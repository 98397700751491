import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { LocationOn } from "@mui/icons-material";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CustomTextField } from "../../../components/CustomTextField";
import { CREATE_FEEDBACK } from "../../../graphql/user";

export default function Contact() {
  const [sendFeedback, { loading }] = useMutation(CREATE_FEEDBACK);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: validator,
    defaultValues: {},
  });

  const onSubmit = async (values) => {
    try {
      const { data } = await sendFeedback({
        variables: {
          input: {
            ...values,
          },
        },
      });

      if (data?.createPartnership?.status === "success") {
        window.location = data?.createPartnership?.data?.checkout_url;
      }
      reset();
      toast.success("You have Successfully Contacted !", {
        autoClose: 500,
      });
    } catch (error) {
      toast.error(error.message, {
        autoClose: 500,
      });
    }
  };

  return (
    <section
      style={{ marginTop: "100px" }}
      part="box"
      className="section contact"
      id="contact"
      aria-label="contact"
    >
      <h2 className="h2 section-title">Contact With Us</h2>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6" style={{ padding: "10px" }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="container-fluid">
                    <div className="row gap-0">
                      <div className="col-lg-12 p-3">
                        <li className="contact-item">
                          <div className="contact-card">
                            <div className="card-icon">
                              <ion-icon
                                name="map-outline"
                                aria-hidden="true"
                              ></ion-icon>
                            </div>

                            <div className="card-content">
                              <h3 className="h3 card-title">Visit Here</h3>

                              <address className="card-address">
                                <a
                                  href="https://maps.app.goo.gl/6JtwvhAMQE9n2gy99"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <LocationOn sx={{ fontSize: "1.5rem" }} />{" "}
                                  No.1 Bishoftu Kajima Bridge / ቁ.1
                                  ቢሾፍቱ/ደብረዘይት/ቃጂማ ድልድይ
                                </a>{" "}
                                {/* <br /> */}
                                <a
                                  href="https://maps.app.goo.gl/K6F9yoyNRWKN1sCdA"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <LocationOn sx={{ fontSize: "1.5rem" }} /> No
                                  2. Addis Ababa Saris Abo Farm / ቁ.2 አዲስ አበባ
                                  ሳሪስ አቦ እርሻ መሳሪያዎች ፊት ለፊት
                                </a>
                                <a
                                  href="https://maps.app.goo.gl/K6F9yoyNRWKN1sCdA"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <LocationOn sx={{ fontSize: "1.5rem" }} />{" "}
                                  No.3 Diredewa Kebele 04 Gende Loni,Next to Aba
                                  Yohannes School Beside Abdurahim Garage / ቁ.3
                                  ድሬ-ደዋ ቀበሌ 04 ገንደ ሎኒ ሰፈር አባ ዮሐንስ ት/ቤት ወረድ ብሎ
                                  አብዱራሂም ጋራጅ አጠገብ።{" "}
                                </a>
                                {/* <a
                                  href="https://maps.app.goo.gl/K6F9yoyNRWKN1sCdA"
                                  target="_blank"
                                  rel="noreferrer"
                                ></a> */}
                                <br />
                                <br />
                              </address>
                            </div>
                          </div>
                        </li>
                      </div>
                      <div className="col-lg-6 p-3">
                        <li className="contact-item">
                          <div className="contact-card">
                            <div className="card-icon">
                              <ion-icon
                                name="headset-outline"
                                aria-hidden="true"
                              ></ion-icon>
                            </div>

                            <div className="card-content">
                              <h3 className="h3 card-title">Call Here</h3>
                              <a href="tel:+251910947135" className="card-link">
                                +251910947135
                              </a>
                              <a href="tel:+251993466179" className="card-link">
                                +251993466179
                              </a>
                              <a href="tel:+251993466177" className="card-link">
                                +251993466177
                              </a>
                            </div>
                          </div>
                        </li>{" "}
                      </div>{" "}
                      <div className="col-lg-6 p-3">
                        <li className="contact-item">
                          <div className="contact-card">
                            <div className="card-icon">
                              <ion-icon
                                name="mail-outline"
                                aria-hidden="true"
                              ></ion-icon>
                            </div>

                            <div className="card-content">
                              <h3 className="h3 card-title">Mail Here</h3>

                              <a
                                href="mailto:anointing.tvn@yahoo.com"
                                className="card-link"
                              >
                                anointing.tvn@yahoo.com
                              </a>
                              <a
                                href="mailto:anointing.tvn@yahoo.com"
                                className="card-link"
                              >
                                anointing.tvn@yahoo.com
                              </a>
                              <a
                                href="mailto:anointing.tvn@yahoo.com"
                                className="card-link"
                              >
                                anointing.tvn@yahoo.com
                              </a>
                            </div>
                          </div>
                        </li>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6" style={{ padding: "20px" }}>
            <form
              action=""
              className="contact-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div
                // className="php-email-form"

                style={{ padding: 0 }}
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <CustomTextField
                      control={control}
                      name={"name"}
                      label={"Full Name"}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <CustomTextField
                      control={control}
                      name={"email"}
                      label={"Email"}
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-6 form-group">
                    <CustomTextField
                      control={control}
                      name={"subject"}
                      label={"Subject"}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <CustomTextField
                      control={control}
                      name={"phone"}
                      label={"Phone Number"}
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-12 form-group mt-5 mt-md-0">
                    <CustomTextField
                      control={control}
                      name={"message"}
                      label={"Your Message"}
                      multiline
                      rows={4}
                    />
                  </div>{" "}
                </div>

                <button type="submit" className="btn btn-primary mt-4">
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>

        <ul className="contact-list"></ul>
      </div>
    </section>
  );
}
const validator = yupResolver(
  Yup.object().shape({
    name: Yup.string().required("First Name is required!"),
    phone: Yup.string().required("Phone is required!"),
    email: Yup.string()
      .email("Enter a Valid Email!")
      .required("Email is required!"),

    subject: Yup.string().required("Address is required!"),
    message: Yup.string().required(),
  })
);
