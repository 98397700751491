import React from "react";
import AboutUs from "./AboutUs";
import BookPurchase from "./BookPurchase";
import Hero from "./Hero";
import LatestBlog from "./LatestBlog";
import ProgramDays from "./ProgramDays";
import WhoWeAre from "./WhoWeAre";

export default function Home() {
  return (
    <>
      <Hero />

      <WhoWeAre />

      <AboutUs />
      {/* <AboutUs /> */}

      {/* <BookPurchase /> */}

      <ProgramDays />

      <LatestBlog />
    </>
  );
}
