import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";

export default function DataGridWrapper({ toolbars, children }) {
  const { pathname } = useLocation();

  return (
    <Box
      height={"100%"}
      sx={{
        "& .MuiButton-root": { fontSize: "1.35rem" },
        "& .MuiDataGrid-cell": {
          fontSize: "1.25rem",
        },
      }}
    >
      <Stack direction={"row"} pb={1} alignItems={"center"}>
        <Stack flex={1}>
          <div className="pagetitle" style={{ marginBottom: "0" }}>
            {/* <h1>{t("Dashboard")}</h1> */}
            <nav>
              <ol className="breadcrumb">
                {pathname?.split("/").map(
                  (path) =>
                    path && (
                      <li
                        className="breadcrumb-item active"
                        style={{ textTransform: "capitalize" }}
                      >
                        {path.replaceAll("-", " ")}
                      </li>
                    )
                )}
              </ol>
            </nav>
          </div>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          {toolbars?.map((toolbar) => (
            <Button variant="contained" onClick={toolbar.onClick}>
              {toolbar.label}
            </Button>
          ))}
        </Stack>
      </Stack>
      {children}
    </Box>
  );
}
