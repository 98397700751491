import React from "react";

import { useMutation } from "@apollo/client";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CAPTURE_BOOK_ORDER, CREATE_BOOK_ORDER } from "../../../graphql/book";

export default function PayPalButtonForBookPurchase({ watch, trigger }) {
  const navigate = useNavigate();

  const [createOrderMut, { ...createOrderMutation }] =
    useMutation(CREATE_BOOK_ORDER);
  const [captureOrder, { ...captureOrderMut }] =
    useMutation(CAPTURE_BOOK_ORDER);

  async function createOrder(_) {
    console.log({ _ });
    const isValid = await trigger([
      "first_name",
      "last_name",
      "phone",
      "email",
      "payment_method",
      "amount",
    ]);

    let orderId;

    if (isValid) {
      try {
        const { data } = await createOrderMut({
          variables: {
            input: {
              first_name: watch("first_name"),
              last_name: watch("last_name"),
              phone: watch("phone"),
              email: watch("email"),
              payment_method: watch("payment_method"),

              amount: parseFloat(watch("amount")),
            },
          },
        });

        console.log({ data });

        orderId = data?.createBookPurchaseOrder;
        // return data?.createBookPurchaseOrder;
      } catch (error) {
        console.log(error);
        // toast.error("Please Fill all Fields");
      }

      console.log({ orderId });

      return orderId;
    } else {
      // toast.error("Please Fill all Fields");
    }
  }

  async function onApprove(data) {
    console.log({ data });
    try {
      const response = await captureOrder({
        variables: { orderId: data.orderID },
      });

      console.log({ response });

      if (
        response.data?.captureBookPurchaseOrder?.result?.status === "COMPLETED"
      ) {
        navigate("/payment-success/succeedeed");
      }
    } catch (error) {
      // console.log(error);
      // toast.error(error.message);
      // toast.error("Please Fill all Fields");
    }
  }

  function onError(error) {
    toast.error("Please Fill all Fields");
    // toast.error(error.message);
    // Do something with the error from the SDK
  }

  return (
    <PayPalScriptProvider
      options={{
        components: "card-fields,buttons",
        clientId:
          "AUMzonKF_w4Gov_RZ_SmZfCYkStAkghOQSixBNWtYG9FD4FROnsiohAsqiCtgisPUhEGN8U_H2ZIO5l0",
      }}
    >
      <PayPalButtons
        createOrder={createOrder}
        onApprove={onApprove}
        onError={onError}
      />
    </PayPalScriptProvider>
  );
}
