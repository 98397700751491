import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Hero() {
  const { currentUser } = useSelector((state) => state.auth);

  return (
    <>
      <main>
        <article>
          <section
            className="section hero bg-dark has-after has-bg-image"
            id="home"
            aria-label="hero"
            data-section
            style={{
              backgroundImage: "url(/assets/images/1716708358Hero.jpg)",
            }}
          >
            <div style={{ minHeight: "550px" }} className="container">
              <div className="hero-content"></div>
            </div>
          </section>
        </article>
      </main>
    </>
  );
}
