import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff, Warning } from "@mui/icons-material";
import { Alert, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CustomTextField } from "../../components/CustomTextField";
import { LOGIN_USER, REQUEST_RESET_PASSWORD } from "../../graphql/user";
import { loginFinished } from "../../redux/slices/authSlice";

export default function Login() {
  const { t } = useTranslation();
  const { state } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPwd, setShowPwd] = useState(false);

  const [loginUser, { loading }] = useMutation(LOGIN_USER);
  const [requestResetPwd, { ...requestResetPwdMut }] = useMutation(
    REQUEST_RESET_PASSWORD
  );

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: validator,
    defaultValues: {
      plan: "Monthly",
      currency: "ETB",
    },
  });

  const onSubmit = async () => {
    const isValid = await trigger(["email", "password"]);

    if (isValid)
      try {
        const { data } = await loginUser({
          variables: {
            input: {
              email: watch("email"),
              password: watch("password"),
            },
          },
        });

        dispatch(loginFinished(data?.loginUser));

        if (data?.loginUser?.user?.role === "admin") navigate("/admin");
        else navigate("/");

        reset();
        toast.success("You have Successfully logged in!", { autoClose: 500 });
      } catch (error) {
        console.log(error);
        toast.error("Email or Password Incorrect", {
          autoClose: 500,
        });
      }
  };

  const requestResetPassword = async () => {
    const isValid = await trigger(["email"]);

    if (isValid)
      try {
        const { data } = await requestResetPwd({
          variables: {
            email: watch("email"),
          },
        });

        reset();
        toast.success("You received an email!", { autoClose: 500 });

        navigate("/");
      } catch (error) {
        toast.error(error.message, {
          autoClose: 500,
        });
      }
  };

  return (
    <>
      {" "}
      <section style={{ marginTop: "45px" }} class="section"></section>
      <h2 style={{ textAlign: "center" }} class="h2 section-title">
        Join us
      </h2>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <form className="php-email-form">
              <div className="pt-0 pb-2">
                <h5 className="card-title text-center pb-0 fs-4">
                  {t("Login into an Account")}
                </h5>
                <p className="text-center small">
                  {t("Enter your personal details to login account")}
                </p>
              </div>

              <div className="row">
                <div className="col-md-12 form-group">
                  {state?.unverified && (
                    <Alert variant="filled" color="error" icon={<Warning />}>
                      {t("Please Check Your Email to Verify !")}
                    </Alert>
                  )}
                </div>{" "}
              </div>
              <div className="row">
                <div className="col-md-12 form-group">
                  <CustomTextField
                    control={control}
                    name={"email"}
                    label={"Email"}
                  />
                </div>
                <div className="col-md-12 form-group mt-3 mt-md-0">
                  <CustomTextField
                    control={control}
                    name={"password"}
                    label={"Password"}
                    type={showPwd ? "text" : "password"}
                    endAdornment={
                      <IconButton onClick={() => setShowPwd(!showPwd)}>
                        {!showPwd ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    }
                  />
                </div>
              </div>

              <div className="text-center mt-3" style={{ marginTop: "2rem" }}>
                <button
                  className="submit-btn btn btn-primary"
                  type="button"
                  onClick={loading ? () => {} : onSubmit}
                >
                  {t(loading ? "Loading..." : "Login")}
                </button>
              </div>

              <div
                className="col-12 d-flex justify-content-between"
                style={{ paddingBottom: "5rem" }}
              >
                <p className="small mb-0 d-flex">
                  {t("Not sign up yet?")}{" "}
                  <Link
                    to={"/sign-up"}
                    style={{ color: "#fad107", marginLeft: "1rem" }}
                  >
                    {t("Sign Up")}
                  </Link>
                </p>
                <p className="small mb-0">
                  <button
                    type="button"
                    onClick={requestResetPassword}
                    style={{ color: "#fad107" }}
                  >
                    {t("Reset Password")}
                  </button>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

const validator = yupResolver(
  Yup.object().shape({
    email: Yup.string()
      .email("Enter a Valid Email!")
      .required("Email is required!"),
    password: Yup.string().required("Password is Required").min(6),
    // .matches(strongPasswordRegex, "Use strong passowrd"),
  })
);
