import React from "react";

export default function WhoWeAre() {
  return (
    <>
      {" "}
      <section className="section about" id="about" aria-label="about">
        <h2
          style={{
            marginInline: "auto",
            fontSize: "40px",
            textAlign: "center",
          }}
          className="h2 section-subtitle"
        >
          {/* WHO WE ARE */}
        </h2>
        <div
          style={{ marginTop: "50px", fontFamily: "roboto" }}
          className="container"
        >
          <div className="about-content">
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              “For the kingdom of God is not in word, but in power.” <br /> — 1
              Corinthians 4:20
              <br />
            </p>

            <p style={{ fontSize: "1.75rem" }} className="section-text">
              “... the yoke shall be destroyed because of the anointing.” <br />{" "}
              — Isaiah 10:27{" "}
            </p>
            <h2
              style={{
                marginInline: "auto",
                fontSize: "25px",
                textAlign: "center",
              }}
              className="h2 section-subtitle"
            >
              --
            </h2>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              “የእግዚአብሔር መንግሥት በኃይል ነው እንጂ በቃል አይደለምና።” <br /> — 1ኛ ቆሮንቶስ 4፥20
            </p>
            <br />
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              “በዚያም ቀን ሸክሙ ከጫንቃህ ቀንበሩም ከአንገትህ ላይ ይወርዳል፤ ቀንበሩም ከውፍረት የተነሣ ይሰበራል።”{" "}
              <br />— ኢሳይያስ 10፥27
            </p>

            <div className="wrapper">
              <div className="about-coach">
                <div style={{ margin: "auto" }}>
                  <h3 className="h4 coach-name"></h3>
                </div>
              </div>
            </div>
          </div>

          <div className="about-banner has-after">
            <img
              src="/assets/images/1716563741900.jpg"
              width="460"
              height="400"
              loading="lazy"
              alt="about banner"
              className="w-100"
            />

            <img
              alt="fjdkls"
              src="./assets/images/"
              width="650"
              height="154"
              loading="lazy"
              className="abs-img w-100"
            />
          </div>
        </div>
      </section>
      {/* who are we */}
      <section className="section about" id="about" aria-label="about">
        <h2
          style={{
            marginInline: "auto",
            fontSize: "40px",
            textAlign: "center",
          }}
          className="h2 section-subtitle"
        >
          WHO WE ARE
        </h2>
        <div
          style={{ marginTop: "50px", fontFamily: "roboto" }}
          className="container"
        >
          <div className="about-banner has-after">
            <img
              src="/assets/images/about.jpg"
              width="460"
              height="400"
              loading="lazy"
              alt="about banner"
              className="w-100"
            />

            <img
              alt="fjdkls"
              src="./assets/images/"
              width="650"
              height="154"
              loading="lazy"
              className="abs-img w-100"
            />
          </div>

          <div className="about-content">
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              Welcome to Spirit Anointed people of God international church, Our
              spiritual father and mother is prophet deresse lakew and prophetes
              Sara Abdurehim. Our ministry was founded and established in May
              2014, at Bishoftu town in Ethiopia. prophet deresse lakew The
              prophet of, is called of God for the Gospel of Sonship and
              Immortality – Death Impossible OUR CARDINAL BELIEF
            </p>

            <h2
              style={{
                marginInline: "auto",
                fontSize: "25px",
                textAlign: "center",
              }}
              className="h2 section-subtitle"
            >
              Our Mission{" "}
            </h2>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              Taking the gospel of sonship, incorruption and immortality to the
              nations of the world
            </p>
            <h2
              style={{
                marginInline: "auto",
                fontSize: "25px",
                textAlign: "center",
              }}
              className="h2 section-subtitle"
            >
              Our Vision
            </h2>

            <p style={{ fontSize: "1.75rem" }} className="section-text">
              Building a generation of remnants of the body of Christ that will
              not see physical death, who through the spirit of revelation and
              renewal of the mind will transform to either live and remain or
              ascend to heaven to exit the earth at will.
            </p>

            <div className="wrapper">
              <div className="about-coach">
                <div style={{ margin: "auto" }}>
                  <h3 className="h4 coach-name"></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
