import React from "react";
import "./generoushand.css";

export default function GenerousHand() {
  return (
    <>
      <section
        className="section about"
        id="about"
        aria-label="about"
        style={{ marginTop: "120px" }}
      >
        <h2
          style={{
            marginInline: "auto",
            fontSize: "40px",
            textAlign: "center",
          }}
          className="h2 section-subtitle"
        >
          Generous Hand / ለጋስ እጆች
        </h2>
        <div
          style={{ marginTop: "50px", fontFamily: "roboto" }}
          className="container"
        >
          <div className="about-content">
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              Generous hands are blessed hands because they give bread to the
              poor. <br /> Proverbs 22:9
            </p>

            <p style={{ fontSize: "1.75rem" }} className="section-text">
              Generosity is the virtue of freely giving good things. Notice it’s
              not just giving things; it’s giving goodthings, both material and
              immaterial. When they hear the word “generosity,” some people will
              first think about giving money. Other people might be giving time,
              service, love, or forgiveness. These are all different and
              important ways we can express generosity, both materially and
              immaterially.
            </p>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              With all these good things, someone can be generous or miserly.
              Generosity means giving these things freely and even
              sacrificially—not grudgingly or forced. As 2 Corinthians 9:7 (NIV)
              says, “Each of you should give what you have decided in your heart
              to give, not reluctantly or under compulsion, for God loves a
              cheerful giver.”
            </p>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              Generosity is a practice that when you do it again and again,
              becomes a routine. This habit, in time, becomes an instinctive
              impulse that helps give shape to a virtuous, Jesus-like life.
            </p>

            <div className="wrapper">
              <div className="about-coach">
                <div style={{ margin: "auto" }}>
                  <h3 className="h4 coach-name"></h3>
                </div>
              </div>
            </div>
          </div>

          <div className="about-content">
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              ለጋስ እጆች የተባረኩ እጆች ናቸው ለድሆች እንጀራ ይሰጣሉና። <br />
              ምሳሌ 22:9
            </p>

            <p style={{ fontSize: "1.75rem" }} className="section-text">
              ለጋስነት በጎ ነገርን በነጻነት የመስጠት በጎነት ነው። ነገሮችን መስጠት ብቻ እንዳልሆነ አስተውል; ቁሳዊ
              እና ቁሳዊ ያልሆኑ ጥሩ ነገሮችን ይሰጣል። "ልግስና" የሚለውን ቃል ሲሰሙ አንዳንድ ሰዎች በመጀመሪያ
              ገንዘብ ስለመስጠት ያስባሉ. ሌሎች ሰዎች ጊዜ፣ አገልግሎት፣ ፍቅር ወይም ይቅርታ እየሰጡ ይሆናል። እነዚህ
              ሁሉ በቁሳዊም ሆነ በግብታዊነት ልግስናን የምንገልጽባቸው የተለያዩ እና ጠቃሚ መንገዶች ናቸው።
            </p>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              በእነዚህ ሁሉ መልካም ነገሮች አንድ ሰው ለጋስ ወይም ምስኪን ሊሆን ይችላል. ልግስና ማለት እነዚህን
              ነገሮች በነጻነት እና አልፎ ተርፎም በመስዋዕትነት መስጠት ማለት ነው—በቂም ሆነ በግድ አይደለም። 2ኛ
              ቆሮንቶስ 9፡7 “እግዚአብሔር በደስታ የሚሰጠውን ይወዳልና እያንዳንዳችሁ በልባችሁ የወሰናችሁትን በኀዘን
              ወይም በግድ አይስጥ።
            </p>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              ልግስና ደጋግመህ ስታደርገው የተለመደ ተግባር ይሆናል። ይህ ልማድ ከጊዜ በኋላ በጎ ምግባር ያለው
              ኢየሱስን የመሰለ ሕይወት እንዲፈጠር የሚረዳ በደመ ነፍስ የሚገፋ ግፊት ይሆናል።
            </p>
            <div className="wrapper">
              <div className="about-coach">
                <div style={{ margin: "auto" }}>
                  <h3 className="h4 coach-name"></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          style={{ marginTop: "50px", fontFamily: "roboto" }}
          className="container"
        >
          <div className="about-content">
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              “For God is not unrighteous to forget your work and labour of
              love, which ye have shewed toward his name, in that ye have
              ministered to the saints, and do minister.” <br /> — Hebrews 6፥10
              (KJV)
            </p>
            <h2
              style={{
                marginInline: "auto",
                fontSize: "25px",
                textAlign: "center",
              }}
              className="h2 section-subtitle"
            >
              --
            </h2>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              “እግዚአብሔር፥ ቅዱሳንን ስላገለገላችሁ እስከ አሁንም ስለምታገለግሉአቸው፥ ያደረጋችሁትን ሥራ ለስሙም
              ያሳያችሁትን ፍቅር ይረሳ ዘንድ ዓመፀኛ አይደለምና።” <br /> — ዕብራውያን 6፥10
              <br />
            </p>

            <div className="wrapper">
              <div className="about-coach">
                <div style={{ margin: "auto" }}>
                  <h3 className="h4 coach-name"></h3>
                </div>
              </div>
            </div>
          </div>

          <div className="about-content">
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              “Pure religion and undefiled before God and the Father is this, To
              visit the fatherless and widows in their affliction, and to keep
              himself unspotted from the world.” <br /> — James 1፥27 (KJV)
            </p>
            <h2
              style={{
                marginInline: "auto",
                fontSize: "25px",
                textAlign: "center",
              }}
              className="h2 section-subtitle"
            >
              --
            </h2>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              “ንጹሕ የሆነ ነውርም የሌለበት አምልኮ በእግዚአብሔር አብ ዘንድ ይህ ነው፤ ወላጆች የሌላቸውን ልጆች
              ባልቴቶችንም በመከራቸው መጠየቅ፥ በዓለምም ከሚገኝ እድፍ ሰውነቱን መጠበቅ ነው።” <br /> — ያዕቆብ
              1፥27
              <br />
            </p>
            <div className="wrapper">
              <div className="about-coach">
                <div style={{ margin: "auto" }}>
                  <h3 className="h4 coach-name"></h3>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>

      <section
        className="section about"
        id="about"
        aria-label="about"
        // style={{ marginTop: "100px" }}
      >
        <div class="charity-gallery" style={{ margin: "auto" }}>
          {[...Array(14).keys()].map((n) => (
            <figure class="imageRoll">
              <img
                src={`/assets/images/charity/charity (${n + 1}).jpeg`}
                alt="sdfsd"
              />
            </figure>
          ))}
        </div>
      </section>
    </>
  );
}
