/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function ProgramDays() {
  return (
    <section
      className="section class bg-dark has-bg-image"
      id="class"
      aria-label="class"
      style={{ backgroundImage: "url(assets/images/classes-bg.png" }}
    >
      <div className="container">
        <p className="section-subtitle">Program Days / የፕሮግራም ቀናት </p>

        <h2 className="h2 section-title text-center"></h2>

        <ul className="class-list has-scrollbar">
          <li className="scrollbar-item">
            <div className="class-card">
              <figure
                className="card-banner img-holder"
                style={{ "--width": "416", "--height": "350" }}
              >
                <img
                  src="assets/images/sund.jpg"
                  width="416"
                  height="240"
                  loading="lazy"
                  alt="program_image_1"
                  className="img-cover"
                />
              </figure>
              {/* ehudna maksegno bishoftu */}
              <div className="card-content">
                <div className="title-wrapper">
                  <img
                    src="assets/images/clock.svg"
                    width="34"
                    height="34"
                    loading="lazy"
                    alt="Clock"
                  />
                  <h3 className="h3">
                    <a
                      href="#"
                      style={{ marginLeft: "20px", textAlign: "start" }}
                      className="card-title"
                    >
                      ዘወትር እሁድ ከማለዳው 12፡00 ሰዓት ጀምሮ እስከ 7፡30 <br />
                      EVery Sunday From 6:00 AM to 1:30 PM
                      <br />
                      {/* <b>Addis Ababa</b> */}
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </li>

          <li className="scrollbar-item">
            <div className="class-card">
              <figure
                className="card-banner img-holder"
                style={{ "--width": "416", "--height": "350" }}
              >
                <img
                  src="assets/images/tuesday.jpg"
                  width="416"
                  height="240"
                  loading="lazy"
                  alt="program_image_2"
                  className="img-cover"
                />
              </figure>

              <div className="card-content">
                <div className="title-wrapper">
                  <img
                    src="assets/images/clock.svg"
                    width="34"
                    height="34"
                    loading="lazy"
                    alt="Clock"
                  />

                  <h3 className="h3">
                    <a
                      href="#"
                      style={{ marginLeft: "20px" }}
                      className="card-title"
                    >
                      ዘወትር ማክሰኞ ከማለዳው 12፡00 ሰዓት ጀምሮ ሙሉ ቀን <br /> Every Tuesday
                      Morning Starting From 06:00 AM
                      <br />
                      {/* <b>Addis Ababa</b> */}
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </li>
          <li className="scrollbar-item">
            <div className="class-card">
              <figure
                className="card-banner img-holder"
                style={{ "--width": "416", "--height": "350" }}
              >
                <img
                  src="assets/images/tuesday.jpg"
                  width="416"
                  height="240"
                  loading="lazy"
                  alt="program_image_2"
                  className="img-cover"
                />
              </figure>

              <div className="card-content">
                <div className="title-wrapper">
                  <img
                    src="assets/images/clock.svg"
                    width="34"
                    height="34"
                    loading="lazy"
                    alt="Clock"
                  />

                  <h3 className="h3">
                    <a
                      href="#"
                      style={{ marginLeft: "20px" }}
                      className="card-title"
                    >
                      ዘወትር አርብ ከማለዳው 01፡00 ሰዓት ጀምሮ ሙሉ ቀን <br /> Every Friday
                      Starting From Morning 07:00 AM
                      <br />
                      {/* <b>Addis Ababa</b> */}
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </li>

          <li className="scrollbar-item">
            <div className="class-card">
              <figure
                className="card-banner img-holder"
                style={{ "--width": "416", "--height": "350" }}
              >
                <img
                  src="assets/images/thursday.jpg"
                  width="416"
                  height="240"
                  loading="lazy"
                  alt="program_image_3"
                  className="img-cover"
                />
              </figure>

              <div className="card-content">
                <div className="title-wrapper">
                  <img
                    src="assets/images/clock.svg"
                    width="34"
                    height="34"
                    loading="lazy"
                    alt="Clock"
                  />

                  <h3 className="h3">
                    <a
                      href="#"
                      style={{ marginLeft: "20px" }}
                      className="card-title"
                    >
                      ዘወትር ሐሙስ ከማለዳው 1፡00 ሰዓት ጀምሮ
                      <br />
                      Every Thursday Morning Starting From 07:00 AM
                      {/* <b>Addis Ababa</b> */}
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
}
