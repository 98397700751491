import { useMutation, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Add, Delete, Edit, Launch, SendToMobile } from "@mui/icons-material";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Yup from "yup";
import CustomModal from "../../../components/CustomModal";
import { CustomTextField } from "../../../components/CustomTextField";
import DataGridWrapper from "../../../components/DataGridWrapper";
import {
  DELETE_BIBLE_STUDY_APPLICATION,
  GET_BIBLE_STUDY_APPLICATIONS,
  REGISTER_MEMBERS_BS,
  SEND_BULK_EMAIL_FOR_MEMBERS,
} from "../../../graphql/bible_study";

export default function BibleStudyApplications() {
  const { t } = useTranslation();

  const [selectedRows, setSelectedRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [open, setOpen] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);

  const { data, loading, refetch } = useQuery(GET_BIBLE_STUDY_APPLICATIONS);
  const [deleteMember, { loading: deleteLoading }] = useMutation(
    DELETE_BIBLE_STUDY_APPLICATION
  );
  const actions = [
    // {
    //   icon: <Launch />,
    //   onClick: () => {},
    //   color: "primary",
    // },
    // {
    //   icon: <Edit />,
    //   onClick: () => {},
    //   color: "success",
    // },
    {
      icon: <Delete />,
      onClick: async ({ row }) => {
        const confirm = window.confirm(
          "Are you sure you want to delete this Member?"
        );
        if (confirm)
          try {
            await deleteMember({ variables: { id: row?.id } });
            toast.success("Member deleted successfully!");
            refetch();
          } catch (error) {
            toast.error("Error deleting Member");
          }
      },
      color: "error",
    },
  ];

  const columns = [
    {
      field: "fullname",
      headerName: "Member Name",
      flex: 1,
      renderCell: ({ row }) => {
        return row.first_name + " " + row.last_name;
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 150,
      renderCell: (param) => (
        <Stack direction={"row"}>
          {actions.map((action) => (
            <IconButton
              color={action?.color || "primary"}
              sx={{ fontSize: "2.75rem" }}
              onClick={() => action.onClick(param)}
            >
              {action.icon}
            </IconButton>
          ))}
        </Stack>
      ),
    },
  ];

  const toolbars = [
    {
      label: "Send Bulk Email",
      icon: <SendToMobile />,
      onClick: () => {
        if (rowSelectionModel.length === 0) {
          toast.error("Please Select at least 1 parter !", { autoClose: 500 });
        } else {
          const selectedRowData = rowSelectionModel.map((selected_id) =>
            data?.bibleStudyApplications?.find(
              (partner) => partner?.id === selected_id
            )
          );

          setSelectedRows(selectedRowData);

          setOpen(true);
        }
      },
    },
    {
      label: "Add New Member",
      icon: <Add />,
      onClick: () => setOpenRegister(true),
    },
  ];

  return (
    <DataGridWrapper toolbars={toolbars}>
      <DataGrid
        density="compact"
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "1.35rem",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "2.75rem",
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#145ca3e8",
            color: "white",
          },
        }}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        columns={columns}
        rows={data?.bibleStudyApplications || []}
        loading={loading}
      />

      <SendBulkEmailForm
        open={open}
        onClose={() => setOpen(false)}
        title={"Send Email to all Members"}
        selectedRows={selectedRows}
      />
      <AddBibleStudyMember
        open={openRegister}
        onClose={() => setOpenRegister(false)}
        refetch={refetch}
      />
    </DataGridWrapper>
  );
}

function SendBulkEmailForm({ open, onClose, title, refetch, selectedRows }) {
  const [createRecord, { loading }] = useMutation(SEND_BULK_EMAIL_FOR_MEMBERS);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: validator,
    defaultValues: {},
  });

  const onSubmit = async (values) => {
    try {
      const { data } = await createRecord({
        variables: {
          input: {
            ...values,
            users: selectedRows?.map((row) => ({
              first_name: row?.first_name,
              last_name: row?.last_name,
              email: row?.email,
            })),
          },
        },
      });

      // refetch();
      reset();
      onClose();
      toast.success("Bulk Email Successfully Sent!", {
        autoClose: 500,
      });
    } catch (error) {
      toast.error(error.message, {
        autoClose: 500,
      });
    }
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={title}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
    >
      <Grid container columnSpacing={4} rowSpacing={2}>
        <Grid item lg={12}>
          <CustomTextField control={control} name={"title"} label={"Title"} />
        </Grid>

        <Grid item lg={12}>
          <CustomTextField
            control={control}
            name={"subject"}
            label={"Subject"}
          />
        </Grid>

        <Grid item lg={12}>
          <CustomTextField
            control={control}
            name={"body"}
            label={"Body"}
            multiline
            rows={15}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
}

function AddBibleStudyMember({ open, onClose, title, refetch }) {
  const [createRecord, { loading }] = useMutation(REGISTER_MEMBERS_BS);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: member_validator,
    defaultValues: {},
  });

  const onSubmit = async (values) => {
    try {
      const { data } = await createRecord({
        variables: { input: { ...values } },
      });

      refetch();
      reset();
      onClose();
      toast.success("Prophetic School Session Successfully Added!", {
        autoClose: 500,
      });
    } catch (error) {
      toast.error(error.message, {
        autoClose: 500,
      });
    }
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={title}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
    >
      <Grid container columnSpacing={4} rowSpacing={2}>
        <Grid item lg={6}>
          <CustomTextField
            control={control}
            name={"first_name"}
            label={"First Name"}
          />{" "}
        </Grid>{" "}
        <Grid item lg={6}>
          <CustomTextField
            control={control}
            name={"last_name"}
            label={"Last Name"}
          />
        </Grid>
        <Grid item lg={6}>
          <CustomTextField control={control} name={"phone"} label={"Phone"} />
        </Grid>
        <Grid item lg={6}>
          <CustomTextField control={control} name={"email"} label={"Email"} />
        </Grid>
        <Grid item lg={6}>
          <CustomTextField
            control={control}
            name={"payment_method"}
            label={"Payment Method"}
            select
            options={["Local Currency", "Paypal"]}
          />
        </Grid>
        <Grid item lg={6}>
          <CustomTextField
            control={control}
            name={"payment_amount"}
            label={"Payment Amount"}
            type="number"
            endAdornment={
              <Typography fontSize={"1.5rem"}>
                {watch("payment_method") === "Paypal" ? "USD" : "ETB"}
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
}

const validator = yupResolver(
  Yup.object().shape({
    body: Yup.string().required("Body is required"),
    title: Yup.string().required("Title is required"),
    subject: Yup.string().required("Subject is required"),
  })
);

const member_validator = yupResolver(
  Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    payment_method: Yup.string().required("Payment method is required"),
    payment_amount: Yup.number()
      .positive("Payment amount must be positive")
      .required("Payment amount is required"),
    phone: Yup.string().required("Phone number is required"),
  })
);
