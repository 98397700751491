import React from "react";
import { useLocation } from "react-router";

export default function SingleBlog() {
  const { state } = useLocation();
  return (
    <>
      <section
        id="featured-services"
        className="featured-services"
        style={{ marginTop: "15rem" }}
      >
        <h2 className="h2 section-title text-center">{state?.blog?.title}</h2>

        <div className="container">
          <ul
            className="blog-list "
            // style={{ paddingLeft: "15rem", paddingRight: "15rem" }}
          >
            <div className="p-3 col-lg-12">
              <div className="blog-card ">
                <div
                  className="card-banner img-holder"
                  style={{ "--width": "440", "--height": "270" }}
                >
                  <img
                    src={state?.blog?.image}
                    width="440"
                    height="270"
                    loading="lazy"
                    alt="Going to the Church for the first time"
                    className="img-cover"
                  />
                </div>

                <div className="card-content">
                  <h3 className="h3">
                    <a className="card-title" href={"#"}>
                      {state?.blog?.title}
                    </a>
                  </h3>

                  <p className="card-text">{state?.blog?.excerpt}</p>

                  {/* <a
                  style={{ textDecoration: "none" }}
                  className="btn-link has-before"
                  to={"#"}
                >
                  {state?.blog?.body}{" "}
                </a> */}

                  <p className="card-text">{state?.blog?.body}</p>
                </div>
              </div>
            </div>{" "}
          </ul>
        </div>
      </section>
    </>
  );
}
