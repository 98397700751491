/* eslint-disable jsx-a11y/anchor-is-valid */
import { Avatar, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logoutFinished } from "../redux/slices/authSlice";

export default function HomeNavbar() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentUser } = useSelector((state) => state.auth);

  const navbars = [
    {
      label: "Home",
      link: "/#",
      navigated: true,
    },
    {
      label: "Services",
      link: "/services",
    },
    {
      label: "Blog",
      link: "/blog",
    },
    {
      label: "Gallery",
      link: "/gallery",
    },
    {
      label: "Prophetic School",
      link: "/prophetic-school",
    },
    {
      label: "Partnership",
      link: "/partners",
    },
    {
      label: "Visitors",
      link: "/visitors",
    },
    {
      label: "Generous Hand",
      link: "/generous-hand",
    },
    {
      label: "Give",
      link: "/give",
    },

    {
      label: "Contact",
      link: "/contact",
    },

    {
      label: "Login",
      link: "/login",
      hide: currentUser.id,
    },
  ];

  const logout = () => {
    dispatch(logoutFinished());
    navigate("/");
  };

  return (
    <header className="header one" data-header id="header">
      <div className="container">
        <Link to="/" style={{ textDecoration: "none" }} className="logo">
          <img
            alt="logo"
            src="/assets/images/logo1.svg"
            style={{ width: "50px", paddingRight: "10px" }}
          />

          <span className="span">AnointingTv</span>
        </Link>

        <nav className="navbar" id="navbar" data-navbar>
          <button
            className="nav-close-btn"
            aria-label="close menu"
            // data-nav-toggler
            onClick={() => {
              document.getElementById("navbar").classList.remove("active");
            }}
          >
            <ion-icon name="close-sharp" aria-hidden="true"></ion-icon>
          </button>

          <ul className="navbar-list">
            {navbars.map(
              (navbar) =>
                !navbar?.hide && (
                  <li key={navbar.label}>
                    <NavLink
                      to={navbar.link}
                      className={({ isActive, isPending }) =>
                        isPending
                          ? "navbar-link pending"
                          : isActive
                          ? "navbar-link active"
                          : "navbar-link "
                      }
                      onClick={() =>
                        document
                          .getElementById("navbar")
                          .classList.remove("active")
                      }
                    >
                      {navbar.label}
                    </NavLink>
                  </li>
                )
            )}

            {/* <Link
              to="/give"
              style={{ fontWeight: "bold", fontSize: "2rem" }}
              className="btn btn-secondary"
            >
              $ Donate
            </Link> */}
          </ul>
        </nav>

        <Link
          to="/give"
          style={{ fontWeight: "bold", fontSize: "2rem" }}
          className="btn btn-secondary"
        >
          $ Donate
        </Link>

        {currentUser.id && (
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="#"
              // role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                display: "flex",
                direction: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "2px",
              }}
            >
              <Avatar></Avatar>
              <Typography color={"white"} fontSize={"1.5rem"}>
                {currentUser?.first_name + " "}
              </Typography>
            </a>

            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" href="#">
                  {currentUser.first_name + " " + currentUser.last_name}
                </a>
              </li>{" "}
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link class="dropdown-item" to="/my-profile">
                  My Profile
                </Link>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  onClick={() => {
                    dispatch(logoutFinished());
                    navigate("/");
                  }}
                >
                  Sign Out
                </a>
              </li>
            </ul>
          </div>
        )}

        <button
          className="nav-open-btn"
          id="nav-open-btn"
          onClick={() => {
            document.getElementById("navbar").classList.add("active");
          }}
        >
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </button>
      </div>
    </header>
  );
}
