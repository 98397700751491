import { useQuery } from "@apollo/client";
import { Add, Delete, Edit, Launch } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DataGridWrapper from "../../../components/DataGridWrapper";
import { GET_GUEST_HOUSE_PRAYERS } from "../../../graphql/guest_house";

export default function GuestHousePrayerApplications() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const { data, loading, refetch } = useQuery(GET_GUEST_HOUSE_PRAYERS);

  const actions = [
    {
      icon: <Launch />,
      onClick: () => {},
      color: "primary",
    },
    {
      icon: <Edit />,
      onClick: () => {},
      color: "success",
    },
    {
      icon: <Delete />,
      onClick: () => {},
      color: "error",
    },
  ];

  const columns = [
    {
      field: "first_name",
      headerName: "First Name",
      flex: 1,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
    },
    {
      field: "start_date",
      headerName: "From",
      flex: 1,
      renderCell: ({ value }) => new Date(value).toLocaleDateString(),
    },
    {
      field: "end_date",
      headerName: "To",
      flex: 1,
      renderCell: ({ value }) => new Date(value).toLocaleDateString(),
    },
    {
      field: "request_detail",
      headerName: "Request Detail",
      flex: 2,
    },
    // {
    //   field: "Actions",
    //   headerName: "Actions",
    //   width: 150,
    //   renderCell: (param) => (
    //     <Stack direction={"row"}>
    //       {actions.map((action) => (
    //         <IconButton
    //           color={action?.color || "primary"}
    //           sx={{ fontSize: "2.75rem" }}
    //           onClick={() => action.onClick(param)}
    //         >
    //           {action.icon}
    //         </IconButton>
    //       ))}
    //     </Stack>
    //   ),
    // },
  ];

  const toolbars = [
    {
      label: "Add New",
      icon: <Add />,
      onClick: () => setOpen(true),
    },
  ];

  return (
    <DataGridWrapper toolbars={toolbars}>
      <DataGrid
        density="compact"
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "1.35rem",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "2.75rem",
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#145ca3e8",
            color: "white",
          },
        }}
        columns={columns}
        rows={data?.guestHousePrayers || []}
        loading={loading}
      />
    </DataGridWrapper>
  );
}
