import { yupResolver } from "@hookform/resolvers/yup";
import { IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  CustomDateTimePicker,
  CustomTextField,
} from "../../components/CustomTextField";
import { Link, useNavigate } from "react-router-dom";
import { CREATE_USER } from "../../graphql/user";
import { useMutation } from "@apollo/client";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function SignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showPwd, setShowPwd] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);

  const [createUser, { loading }] = useMutation(CREATE_USER);

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: validator,
    defaultValues: {},
  });

  const onSubmit = async () => {
    const isValid = await trigger([
      "first_name",
      "last_name",
      "phone",
      "email",
      "gender",
      "dob",
      "password",
      "confirm_password",
      "address",
    ]);

    if (isValid)
      try {
        await createUser({
          variables: {
            input: {
              first_name: watch("first_name"),
              last_name: watch("last_name"),
              phone: watch("phone"),
              email: watch("email"),
              gender: watch("gender"),
              dob: watch("dob"),
              address: watch("address"),
              password: watch("password"),
              // confirm_password: watch("confirm_password"),
            },
          },
        });

        reset();
        toast.success("User Successfully Registered!", { autoClose: 500 });

        navigate("/login", { state: { unverified: true } });
      } catch (error) {
        toast.error(error.message, {
          autoClose: 500,
        });
      }
  };

  return (
    <>
      <section style={{ marginTop: "30px" }} class="section"></section>
      <h2 style={{ textAlign: "center" }} class="h2 section-title">
        Sign Up and Join Us
      </h2>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <form className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <CustomTextField
                    control={control}
                    name={"first_name"}
                    label={"First Name"}
                  />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <CustomTextField
                    control={control}
                    name={"last_name"}
                    label={"Last Name"}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6 form-group">
                  <CustomTextField
                    control={control}
                    name={"phone"}
                    label={"Phone"}
                  />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <CustomTextField
                    control={control}
                    name={"email"}
                    label={"Email"}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6 form-group">
                  <CustomTextField
                    control={control}
                    name={"gender"}
                    label={"Gender"}
                    select
                    options={["Male", "Female"]}
                  />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <CustomDateTimePicker
                    control={control}
                    name={"dob"}
                    label={"Date of Birth"}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-6 form-group">
                  <CustomTextField
                    control={control}
                    name={"password"}
                    label={"Password"}
                    type={showPwd ? "text" : "password"}
                    endAdornment={
                      <IconButton onClick={() => setShowPwd(!showPwd)}>
                        {!showPwd ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    }
                  />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <CustomTextField
                    control={control}
                    name={"confirm_password"}
                    label={"Confirm Password"}
                    type={showConfirmPwd ? "text" : "password"}
                    endAdornment={
                      <IconButton
                        onClick={() => setShowConfirmPwd(!showConfirmPwd)}
                      >
                        {!showConfirmPwd ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    }
                  />
                </div>
              </div>

              <div className="form-group mt-3">
                <CustomTextField
                  control={control}
                  name={"address"}
                  label={"Address"}
                  multiline
                  rows={1}
                />
              </div>

              <div className="text-center">
                <button
                  className="submit-btn btn btn-primary mt-2"
                  type="button"
                  onClick={loading ? () => {} : onSubmit}
                >
                  {t(loading ? "Loading..." : "Sign Up")}
                </button>
              </div>

              <div className="col-12">
                <p className="small mb-0 d-flex">
                  {t("Already Registered ?")}{" "}
                  <Link
                    to={"/login"}
                    style={{ color: "#fad107", marginLeft: "1rem" }}
                  >
                    {t("Sign In")}
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

const validator = yupResolver(
  Yup.object().shape({
    first_name: Yup.string().required("First Name is required!"),
    last_name: Yup.string().required("Last Name is required!"),
    phone: Yup.string().required("Phone is required!"),
    email: Yup.string()
      .email("Enter a Valid Email!")
      .required("Email is required!"),
    gender: Yup.string().required("Gender is required!"),
    dob: Yup.date().required("Date of Birth is required!"),
    address: Yup.string().required("Address is required!"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters long")
      .required("Password is required!"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required!"),
  })
);
