import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CustomTextField } from "../../../components/CustomTextField";
import { CREATE_PARTNERSHIP } from "../../../graphql/partnership";
import OneTimePaypalButton from "./OneTimePaypalButton";
import RecurringPaypalButton from "./RecurringPaypalButton";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Typography } from "@mui/material";

export default function Partnership() {
  const { t } = useTranslation();
  const params = useParams();

  const { currentUser } = useSelector((state) => state.auth);

  const [createPartnership, { loading }] = useMutation(CREATE_PARTNERSHIP);

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: validator,
    defaultValues: {
      plan: "Monthly",
      currency: "ETB",
      partnership_type: "Recurring",
    },
  });

  useEffect(() => {
    setValue("first_name", currentUser?.first_name);
    setValue("last_name", currentUser?.last_name);
    setValue("email", currentUser?.email);
    setValue("phone", currentUser?.phone);
  }, []);

  const types = ["Weekly", "Monthly", "Quarterly", "Annually"];

  const onSubmit = async (values) => {
    try {
      const { data } = await createPartnership({
        variables: {
          input: {
            ...values,
          },
        },
      });

      if (data?.createPartnership?.status === "success") {
        window.location = data?.createPartnership?.data?.checkout_url;
      }
      reset();
      toast.success("You have Successfully registered for partnership !", {
        autoClose: 500,
      });
    } catch (error) {
      toast.error("Internal Server Error", {
        autoClose: 500,
      });
    }
  };

  return (
    <>
      {" "}
      <section
        className="section about"
        id="about"
        aria-label="about"
        style={{ marginTop: "100px" }}
      >
        <h2
          style={{
            marginInline: "auto",
            fontSize: "40px",
            textAlign: "center",
          }}
          className="h2 section-subtitle"
        >
          Partnership
        </h2>
        <div
          style={{ marginTop: "50px", fontFamily: "roboto" }}
          className="container"
        >
          <div className="about-content">
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              BIBLICAL PARTNERSHIP IS ROOTED IN AND MUST RESULT IN RELATIONSHIPS{" "}
            </p>

            <p style={{ fontSize: "1.75rem" }} className="section-text">
              The primary Greek word translated “partner/s/hip” in the New
              Testament is koinonia. This word appears 19 times in the New
              Testament. Perhaps the most common translation of koinonia is the
              English word “fellowship.” But koinonia and some of its surrogates
              are also often rendered as “partner/s/” in the versions consulted.
              It can also be translated into other English words such as
              participation,sharing, and association.{" "}
            </p>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              Kittel reduces the meaning of koinonia to two bottom lines. First,
              koinonia is, “to share with someone in something”. This first
              notion speaks of participation. We are called into fellowship
              (koinonia) with Christ by God (1 Cor. 1:9). This partnership with
              God is meant to lead us into meaningful partnerships as brothers
              and sisters in God’s family (1 John 1:3). Furthermore, the
              participatory nature of partnership leads to both relational
              intimacy as well as to a fruitful advance of the Gospel (Phil. 1:
              5).
              <br />
            </p>

            <p style={{ fontSize: "1.75rem" }} className="section-text">
              So by partnering with us, become a family.
            </p>

            <div className="wrapper">
              <div className="about-coach">
                <div style={{ margin: "auto" }}>
                  <h3 className="h4 coach-name"></h3>
                </div>
              </div>
            </div>
          </div>

          <div className="about-content">
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              አጋርነት በመጽሐፍ ቅዱሳዊ አጋርነት ላይ የተመሰረተ ነው እና ቤተሰባዊ ግንኙነትን መመስረት አለበት
            </p>

            <p style={{ fontSize: "1.75rem" }} className="section-text">
              በአዲስ ኪዳን ውስጥ “አጋር/ስ/ሂፕ” ተብሎ የተተረጎመው ዋና የግሪክ ቃል koinonia ነው። ይህ ቃል
              በአዲስ ኪዳን 19 ጊዜ ተጠቅሷል። ምናልባት በጣም የተለመደው የኮይኖኒያ ትርጉም “ኅብረት” የሚለው
              የእንግሊዝኛ ቃል ነው። ነገር ግን ኮይኖኒያ እና አንዳንድ ተተኪዎቹ በተማከሩት እትሞች ውስጥ ብዙውን ጊዜ
              እንደ “ባልደረባ/ዎች/” ተደርገው ተወስደዋል። እንዲሁም ወደ ሌሎች የእንግሊዝኛ ቃላት ለምሳሌ እንደ
              ተሳትፎ፣ መጋራት እና ማህበር ሊተረጎም ይችላል።{" "}
            </p>

            <p style={{ fontSize: "1.75rem" }} className="section-text">
              ኪትቴል የኮይኖኒያን ትርጉም ወደ ሁለት የታችኛው መስመሮች ይቀንሳል. በመጀመሪያ፣ koinonia ማለት፣
              “ከአንድ ሰው ጋር በአንድ ነገር ማካፈል” ነው። ይህ የመጀመሪያ ሀሳብ ስለ ተሳትፎ ይናገራል.
              በእግዚአብሔር ከክርስቶስ ጋር ኅብረት (ኮኢኖኒያ) ተጠርተናል (1ቆሮ. 1፡9)። ይህ ከእግዚአብሔር ጋር
              ያለው አጋርነት በእግዚአብሔር ቤተሰብ ውስጥ እንደ ወንድሞች እና እህቶች ትርጉም ያለው ሽርክና ውስጥ
              እንድንገባ ነው (1 ዮሐንስ 1፡3)። በተጨማሪም፣ የአጋርነት አሳታፊ ተፈጥሮ ለሁለቱም ግንኙነታዊ ቅርርብ
              እንዲሁም ወደ ፍሬያማ የወንጌል እድገት ይመራል (ፊልጵ. 1፡5)።
              <br />
            </p>

            <p style={{ fontSize: "1.75rem" }} className="section-text">
              ስለዚህ ከኛ ጋር አጋር በመሆን አንድ ቤተሰብ ይሁኑ።
            </p>

            <div className="wrapper">
              <div className="about-coach">
                <div style={{ margin: "auto" }}>
                  <h3 className="h4 coach-name"></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container ">
        {/* <h2 className="text-center mb-4">Donate and Partner with Us</h2> */}
        <div style={{ marginTop: "140px" }}>
          <h2
            style={{
              marginInline: "auto",
              fontSize: "40px",
            }}
            className="h2 section-subtitle"
          >
            Partnership Form
          </h2>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <form
                className="php-email-form"
                onSubmit={handleSubmit(onSubmit)}
                style={{ padding: 0 }}
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <CustomTextField
                      control={control}
                      name={"first_name"}
                      label={"First Name"}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <CustomTextField
                      control={control}
                      name={"last_name"}
                      label={"Last Name"}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6 form-group">
                    <CustomTextField
                      control={control}
                      name={"phone"}
                      label={"Phone"}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <CustomTextField
                      control={control}
                      name={"email"}
                      label={"Email"}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6 form-group">
                    <CustomTextField
                      control={control}
                      name={"partnership_plan"}
                      label={"Partnership Plan"}
                      select
                      options={[
                        "Every Month",
                        "Every 3 Month",
                        "Every 6 Month",
                        "Every Year",
                      ]}
                      disabled={watch("partnership_type") === "One Time"}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <CustomTextField
                      control={control}
                      name={"payment_method"}
                      label={"Payment Method"}
                      select
                      options={["Local Currency", "Paypal"]}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6 form-group">
                    <CustomTextField
                      control={control}
                      name={"amount"}
                      label={"Amount"}
                      type="number"
                      endAdornment={
                        <Typography fontSize={"1.5rem"}>
                          {watch("payment_method") === "Paypal" ? "USD" : "ETB"}
                        </Typography>
                      }
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <CustomTextField
                      control={control}
                      name={"church_name"}
                      label={"Name of Your Organization"}
                    />
                  </div>
                </div>

                <div className="form-group mt-3"></div>
                <div className="form-group mt-3">
                  <CustomTextField
                    control={control}
                    name={"address"}
                    label={"Address"}
                    multiline
                    rows={1}
                  />{" "}
                </div>
                <div className="form-group mt-3">
                  <CustomTextField
                    control={control}
                    name={"additional_message"}
                    label={"Additional Message"}
                    multiline
                    rows={3}
                  />
                </div>

                <div className="mt-4"></div>

                {watch("payment_method") === "Paypal" ? (
                  // watch("partnership_type") === "One Time" ? (

                  // ) : (
                  //   <RecurringPaypalButton watch={watch} trigger={trigger} />
                  // )

                  <OneTimePaypalButton watch={watch} trigger={trigger} />
                ) : (
                  <div className="d-flex justify-content-center">
                    <button
                      type={loading ? "button" : "submit"}
                      className="btn btn-primary btn-block "
                      style={{
                        minWidth: "100%",
                        height: "5.5rem",
                        backgroundColor: "#ffc439",
                        border: "none",
                        borderRadius: "5px",
                        fontSize: "1.85rem",
                        lineHeight: 1,
                        color: "#003087",
                        fontWeight: "bold",
                        marginBottom: "9px",
                      }}
                    >
                      {loading ? "Loading..." : "Submit"}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const validator = yupResolver(
  Yup.object().shape({
    first_name: Yup.string().required("First Name is required!"),
    last_name: Yup.string().required("Last Name is required!"),
    phone: Yup.string().required("Phone is required!"),
    email: Yup.string()
      .email("Enter a Valid Email!")
      .required("Email is required!"),
    partnership_type: Yup.string().required("partnership Type is required!"),
    partnership_plan: Yup.string().when("partnership_type", {
      is: (value) => value !== "One Time",
      then: (schema) => schema.required("Partnership Plan is required!"),
      otherwise: (schema) =>
        schema.notRequired("partnership Plan is required!"),
    }),
    amount: Yup.number().min(0).required("Amount is required!"),
    payment_method: Yup.string().required("Payment Method is required!"),
    church_name: Yup.string().required("Church Name is is required!"),
    address: Yup.string().required("Address is required!"),
    additional_message: Yup.string().notRequired(),
  })
);
