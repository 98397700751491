import React from "react";
import { Link } from "react-router-dom";

export default function HomeFooter() {
  return (
    <>
      <footer className="footer">
        <div
          className="section footer-top bg-dark has-bg-image"
          style={{ backgroundImage: "url(/assets/images/footer-bg.png)" }}
        >
          <div className="container">
            <div className="footer-brand">
              <a href="#" className="logo">
                <img
                  alt="adf"
                  src="/assets/images/logo1.svg"
                  style={{ width: "50px", paddingRight: "10px" }}
                />
                <span className="span">AnointingTv</span>
              </a>

              <br />

              <div className="wrapper">
                <img
                  src="assets/images/clock.svg"
                  width="34"
                  height="34"
                  loading="lazy"
                  alt="Clock"
                />

                <ul className="footer-brand-list">
                  <li>
                    <p className="footer-brand-title">Sunday</p>

                    <p>6:00am</p>
                  </li>

                  <li>
                    <p className="footer-brand-title">Tuesday</p>

                    <p>6:00Am</p>
                  </li>
                  <li>
                    <p className="footer-brand-title">thursday</p>

                    <p>7:00Am</p>
                  </li>
                </ul>
              </div>
            </div>

            <ul className="footer-list">
              <li>
                <p className="footer-list-title has-before">Our Links</p>
              </li>

              <li>
                <Link to="/" className="footer-link">
                  Home
                </Link>
              </li>

              <li>
                <Link to="/give" className="footer-link">
                  Give
                </Link>
              </li>

              <li>
                <Link to="/services" className="footer-link">
                  Services
                </Link>
              </li>
              <li>
                <Link to="/blog" className="footer-link">
                  Blog
                </Link>
              </li>

              <li>
                <Link to="/gallery" className="footer-link">
                  Gallery
                </Link>
              </li>

              <li>
                <Link to="/prophetic-school" className="footer-link">
                  Prophetic School
                </Link>
              </li>

              <li>
                <Link to="/visitors" className="footer-link">
                  Visitors{" "}
                </Link>
              </li>

              <li>
                <Link to="/contact" className="footer-link">
                  Contact Us
                </Link>
              </li>
            </ul>

            <ul className="footer-list">
              <li>
                <p className="footer-list-title has-before">Contact Us</p>
              </li>

              <li className="footer-list-item">
                <div className="icon">
                  <ion-icon name="location" aria-hidden="true"></ion-icon>
                </div>

                <address className="address footer-link">
                  ቁ.1 ቢሾፍቱ/ደብረዘይት/ቃጂማ ድልድይ <br /> ቁ.2 አዲስ አበባ ሳሪስ አቦ እርሻ መሳሪያዎች
                  ፊት ለፊት <br /> ቁ.3 ድሬ-ደዋ ቀበሌ 04 ገንደ ሎኒ ሰፈር አባ ዮሐንስ ት/ቤት ወረድ ብሎ
                  አብዱራሂም ጋራጅ አጠገብ።
                </address>
              </li>

              <li className="footer-list-item">
                <div className="icon">
                  <ion-icon name="call" aria-hidden="true"></ion-icon>
                </div>

                <div>
                  <a href="tel:+251910947135" className="footer-link">
                    +251910947135
                  </a>

                  <a href="tel:+251993466179" className="footer-link">
                    +251993466179
                  </a>

                  <a href="tel:+251993466177" className="footer-link">
                    +251993466177
                  </a>
                </div>
              </li>

              <li className="footer-list-item">
                <div className="icon">
                  <ion-icon name="mail" aria-hidden="true"></ion-icon>
                </div>

                <div>
                  {/* <!-- <a href="mailto:info@christlovetv.com" className="footer-link">ProphetDerese Lakewshimelis@gmail.com</a> --> */}

                  <a
                    href="mailto:anointing.tvn@gmail.com"
                    className="footer-link"
                  >
                    anointing.tvn@gmail.com
                  </a>
                </div>
              </li>
            </ul>

            <ul className="footer-list">
              <li>
                <p className="footer-list-title has-before">Social Networks</p>
              </li>

              <li>
                <ul className="social-list">
                  <li>
                    <a
                      href="http://facebook.com/Prophet-Deresse-LakewAnointing-Tv-1742086632716843"
                      className="social-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ion-icon name="logo-facebook"></ion-icon>
                    </a>
                  </li>

                  <li>
                    <a
                      href="http://tiktok.com/@prophet_deresselakew"
                      className="social-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ion-icon name="logo-tiktok"></ion-icon>
                    </a>
                  </li>

                  <li>
                    <a
                      href="http://twitter.com/AnointingTvn"
                      className="social-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ion-icon name="logo-twitter"></ion-icon>
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.youtube.com/@ANOINTINGTVCHANNEL"
                      className="social-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ion-icon name="logo-youtube"></ion-icon>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/prophetderesse"
                      className="social-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ion-icon name="logo-instagram"></ion-icon>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <p className="copyright">
              &copy; 2024 AnointingTv. All Rights Reserved By{" "}
              <a href="#" className="copyright-link">
                developer.adulis.market.
              </a>
            </p>

            <ul className="footer-bottom-list">
              <li>
                <a href="#" className="footer-bottom-link has-before">
                  Privacy Policy
                </a>
              </li>

              <li>
                <a href="#" className="footer-bottom-link has-before">
                  Terms & Condition
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      <a
        href="#top"
        className="back-top-btn"
        aria-label="back to top"
        data-back-top-btn
      >
        <ion-icon name="caret-up-sharp" aria-hidden="true"></ion-icon>
      </a>
    </>
  );
}
