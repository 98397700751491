import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Draggable from "react-draggable";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CustomTextField } from "../../../components/CustomTextField";
import { ORDER_BOOK } from "../../../graphql/book";
import PayPalButtonForBookPurchase from "./PayPalButtonForBookPurchase";

export default function BookPurchase() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  return (
    <>
      <section className="section about" id="about" aria-label="about">
        <h2
          style={{
            marginInline: "auto",
            fontSize: "40px",
            textAlign: "center",
          }}
          className="h2 section-subtitle"
        >
          The Laying on of Hands / እጆችን መጫን
        </h2>
        <div
          style={{ marginTop: "50px", fontFamily: "roboto" }}
          className="container"
        >
          <div className="about-content">
            <h1
              style={{
                fontSize: "3rem",
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              ከውስጥ ገጾች{" "}
            </h1>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              “የእጅ መጫን አገልግሎት እንዲሁ በቀላሉ የሚታለፍ አገልግሎት አይደለም ። ይልቁንም በአዲስ ኪዳን
              ቤተ-ክርስቲያናት ውስጥ ልቱ ትኩረት የተሰጠው አገልግሎት ነው እንጂ።” ገጽ 2
              <br />
            </p>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              “በአይሁድ እምነትና በክርስና እምነት ውስጥ የእጅ መጫን አገልግሎት ትኩረት ያገኘበት ዋነኛው ምክኒያት
              ከሰውነት ክፍሎች መካከል እጅ የሰው ልጆች የተግባር እንቅስቃሴ በብዙ መልኩ መገለጫ መሳሪያ መሆኑ ነው።”
              ገጽ 25
            </p>{" "}
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              “አሁን በደረስኩበት መንፈሳዊ ህይወት ማንም ሰው ፈጥኖ በእኔ ላይ እጆቹን ጭኖ እንዲጸልይ አልፈቅድም።
              ለምን የፈለገ ታዋቂ ነብይ፤ ታዋቂ መጋቢ ወይም ታዋቂ ሐዋሪያ አይሆንም፤ ሰውዬ እንደ እግዚአብሔር የሆነ
              ማንነት ከሌለው እጆቹን በእኔ እንዲጭን ፈጽሞ አልፈቅድለትም።” ገጽ 40
            </p>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              “በእጊዚአብሔር ምሪት ካልሆነ በስተቀር ማንም ሰው በእኛ እጆቹን ፈጥኖ ሊጭን አይገባውም። ህይወት በብዙ
              ውጣ ውረድ ውስጥ በምትኖርበት አለም ውስጥ በራሳችን ዝንጉነት ተጨማሪ መከራ ወደ ህይወታችን መጎተት
              የለብንም። ” ገጽ 57
            </p>
            <h2
              style={{
                marginInline: "auto",
                fontSize: "25px",
                textAlign: "center",
              }}
              className="h2 section-subtitle"
            >
              --
            </h2>
            <h1
              style={{
                fontSize: "3rem",
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
                paddingBottom: "1rem",
              }}
            >
              Order Online / ኦንላይል ይዘዙ
            </h1>
            {/* <!-- Button trigger modal --> */}
            {/* <!-- Modal --> */}
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item sm={12} xs={12} lg={6}>
                <button
                  type={"button"}
                  className="btn btn-primary btn-block "
                  style={{
                    minWidth: "100%",
                    height: "5.5rem",
                    backgroundColor: "#ffc439",
                    border: "none",
                    borderRadius: "5px",
                    fontSize: "1.85rem",
                    lineHeight: 1,
                    color: "#003087",
                    fontWeight: "bold",
                    marginBottom: "9px",
                  }}
                  onClick={() => navigate("/book-order")}
                >
                  Order Here
                </button>
              </Grid>
            </Grid>
            <div className="wrapper">
              <div className="about-coach">
                <div style={{ margin: "auto" }}>
                  <h3 className="h4 coach-name"></h3>
                </div>
              </div>
            </div>
          </div>

          <div className="about-banner has-after">
            <img
              src="/assets/images/cover-page.jpg"
              width="460"
              height="400"
              loading="lazy"
              alt="about banner"
              className="w-100"
            />

            <img
              alt="fjdkls"
              src="./assets/images/"
              width="650"
              height="154"
              loading="lazy"
              className="abs-img w-100"
            />
          </div>
        </div>
      </section>

      {/* <BookPurchaseForm
        open={open}
        onClose={() => setOpen(false)}
        title={"Order Book"}
      /> */}
    </>
  );
}

export function BookPurchaseForm({ open, onClose, title, refetch }) {
  const [createChapaOrder, { loading }] = useMutation(ORDER_BOOK);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: validator,
    defaultValues: {
      amount: 100,
      payment_method: "Paypal",
    },
  });

  // useEffect(() => {
  //   if (watch("payment_method") === "Paypal") {
  //     setValue("amount", 100);
  //   } else {
  //     setValue("amount", 1000);
  //   }
  // }, [watch("payment_method")]);

  const onSubmit = async (values) => {
    try {
      const { data } = await createChapaOrder({ variables: { input: values } });

      if (data?.chapaBookPurchase?.status === "success") {
        window.location = data?.chapaBookPurchase?.data?.checkout_url;
      }

      reset();
      onClose();
      toast.success("The Book has successfully Ordered !", {
        autoClose: 500,
      });
    } catch (error) {
      toast.error(error.message, {
        autoClose: 500,
      });
    }
  };

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  return (
    // <Dialog
    //   open={open}
    //   onClose={onClose}
    //   PaperComponent={PaperComponent}
    //   aria-labelledby="draggable-dialog-title"
    //   maxWidth="sm"
    //   fullWidth
    //   sx={{
    //     "& .MuiButton-root": { fontSize: "1.35rem" },
    //     "& .MuiInputBase-input": { fontSize: "1.5rem!important" },
    //     "& .MuiFormLabel-root": { fontSize: "1.5rem!important" },
    //   }}
    // >
    //   <DialogTitle
    //     style={{
    //       cursor: "move",
    //       fontSize: "2.5rem",
    //       borderBottom: 1,
    //       borderColor: "divider",
    //     }}
    //     id="draggable-dialog-title"
    //   >
    //     {title}
    //   </DialogTitle>
    <>
      {" "}
      <section
        className="section about"
        id="about"
        aria-label="about"
        style={{ marginTop: "100px" }}
      >
        <h2
          style={{
            marginInline: "auto",
            fontSize: "40px",
            textAlign: "center",
          }}
          className="h2 section-subtitle"
        >
          Book Order
        </h2>
      </section>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-6">
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent>
                <DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12} lg={6}>
                      <CustomTextField
                        control={control}
                        name={"first_name"}
                        label={"First Name"}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12} lg={6}>
                      <CustomTextField
                        control={control}
                        name={"last_name"}
                        label={"Last Name"}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12} lg={6}>
                      <CustomTextField
                        control={control}
                        name={"phone"}
                        label={"Phone"}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12} lg={6}>
                      <CustomTextField
                        control={control}
                        name={"email"}
                        label={"Email"}
                      />
                    </Grid>
                    {/* <Grid item sm={12} xs={12} lg={6}>
                <CustomTextField
                  control={control}
                  name={"payment_method"}
                  label={"Payment Method"}
                  select
                  options={["Paypal"]}
                />
              </Grid> */}
                    <Grid item sm={12} xs={12} lg={12}>
                      <CustomTextField
                        control={control}
                        name={"amount"}
                        label={"Amount"}
                        type="number"
                        disabled
                        endAdornment={
                          <Typography fontSize={"1.5rem"}>USD</Typography>
                        }
                      />
                    </Grid>
                    <Box height={"1rem"} />
                    <Grid item sm={12} xs={12} lg={12}>
                      <PayPalButtonForBookPurchase
                        watch={watch}
                        trigger={trigger}
                      />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
            </form>{" "}
          </div>
        </div>
      </div>
    </>
    //   <DialogActions>
    //     <Button autoFocus onClick={onClose}>
    //       Cancel
    //     </Button>
    //   </DialogActions>
    // </Dialog>
  );
}

const validator = yupResolver(
  Yup.object().shape({
    first_name: Yup.string().required("First name is required"),

    last_name: Yup.string().required("Last name is required"),

    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]+$/, "Phone number must be numeric")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must be at most 15 digits"),

    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),

    payment_method: Yup.string().required("Payment method is required"),

    amount: Yup.number()
      .required("Amount is required")
      .typeError("Amount must be a number")
      .positive("Amount must be positive")
      .test(
        "is-decimal",
        "Amount must have at most two decimal places",
        (value) => /^\d+(\.\d{1,2})?$/.test(value)
      ),
  })
);
