/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from "@apollo/client";
import { Skeleton } from "@mui/material";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";
import Isotope from "isotope-layout";
import React, { useEffect, useRef, useState } from "react";
import { GET_GALLERY_FOR_USERS } from "../../../graphql/gallery";
import "./gallery.css";

export default function Gallery() {
  const isotopeInstance = useRef(null);
  const gridElement = useRef(null);

  const [activeFilter, setActiveFilter] = useState("*");

  const { data, loading } = useQuery(GET_GALLERY_FOR_USERS);

  useEffect(() => {
    const lightbox = GLightbox({
      selector: ".portfolio-lightbox",
      touchNavigation: true,
      loop: true,
      autoplayVideos: true,
    });

    // Clean up on component unmount
    return () => {
      lightbox.destroy();
    };
  }, []);

  useEffect(() => {
    // Initialize Isotope
    isotopeInstance.current = new Isotope(gridElement.current, {
      itemSelector: ".portfolio-item",
      layoutMode: "fitRows",
    });

    return () => {
      isotopeInstance.current.destroy();
    };

    // Cleanup on unmount
  }, [activeFilter]);

  useEffect(() => {
    if (isotopeInstance.current) {
      isotopeInstance.current.arrange({ filter: activeFilter });
      isotopeInstance.current.on("arrangeComplete", function () {
        window.AOS && window.AOS.refresh(); // Refresh AOS if it's available
      });
    }
  }, [activeFilter]);

  const filterItems = (filter) => {
    setActiveFilter(filter);
  };

  return (
    <section
      id="services"
      className="portfolio"
      style={{ marginTop: "13rem", minHeight: "100vh" }}
    >
      <div className="container">
        <h2
          style={{
            marginInline: "auto",
            fontSize: "40px",
            textAlign: "center",
          }}
          className="h2 section-subtitle"
        >
          Gallery
        </h2>

        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li
                className={activeFilter === "*" && "filter-active"}
                onClick={() => filterItems("*")}
              >
                All
              </li>

              {data?.galleryCategoryForUsers?.map((category) => (
                <li
                  className={
                    activeFilter ===
                      category?.title.toLowerCase().replaceAll(" ", "-") &&
                    "filter-active"
                  }
                  onClick={() =>
                    filterItems(
                      "." + category?.title.toLowerCase().replaceAll(" ", "-")
                    )
                  }
                >
                  {category?.title}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <br />
        <div
          className="row portfolio-container "
          // data-aos="fade-up"
          // data-aos-delay="200"
          ref={gridElement}
        >
          {loading ? (
            <Skeleton height={"20rem"} />
          ) : (
            data?.galleryCategoryForUsers?.map((category) =>
              category?.galleries?.map((gallery) =>
                JSON.parse(gallery?.images || {})?.map((image) => (
                  <div
                    key={image}
                    className={
                      "col-lg-4 col-md-6 portfolio-item filter-app " +
                      category?.title.toLowerCase().replaceAll(" ", "-")
                    }
                    style={{ position: "relative" }}
                    // onClick={()=> }
                  >
                    <a
                      data-gallery="portfolioGallery"
                      className="portfolio-lightbox preview-link"
                      href={image}
                      // style={{ minWidth: "13rem" }}
                    >
                      <img
                        src={image}
                        className="img-fluid"
                        alt=""
                        style={{ minWidth: "13rem" }}
                      />
                    </a>
                    <div className="portfolio-info">
                      <h4>{gallery?.title}</h4>
                      <p>{gallery?.city}</p>
                    </div>
                  </div>
                ))
              )
            )
          )}
        </div>
      </div>
    </section>
  );
}
