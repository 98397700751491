import React from "react";

import { useMutation } from "@apollo/client";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  CAPTURE_BIBLE_STUDY_ORDER,
  CREATE_BIBLE_STUDY_ORDER,
} from "../../../graphql/bible_study";

export default function PaypalButtonForBS({ watch, trigger }) {
  const navigate = useNavigate();

  const [createOrderMut, { ...createOrderMutation }] = useMutation(
    CREATE_BIBLE_STUDY_ORDER
  );
  const [captureOrder, { ...captureOrderMut }] = useMutation(
    CAPTURE_BIBLE_STUDY_ORDER
  );

  async function createOrder(data) {
    const isValid = await trigger([
      "first_name",
      "last_name",
      "phone",
      "email",
      "payment_method",
      "payment_amount",
    ]);

    let orderId;

    if (isValid) {
      try {
        const { data } = await createOrderMut({
          variables: {
            input: {
              bible_study_session_id: watch("bible_study_session_id"),
              first_name: watch("first_name"),
              last_name: watch("last_name"),
              phone: watch("phone"),
              email: watch("email"),
              payment_method: watch("payment_method"),
              payment_amount: parseFloat(watch("payment_amount")),
            },
          },
        });

        orderId = data?.createBibleStudyOrder;
        return data?.createBibleStudyOrder;
      } catch (error) {
        // toast.error("Please Fill all Fields");
      }

      return orderId;
    } else {
      // toast.error("Please Fill all Fields");
    }
  }

  async function onApprove(data) {
    try {
      const response = await captureOrder({
        variables: { orderId: data.orderID },
      });

      if (
        response.data?.captureBibleStudyOrder?.result?.status === "COMPLETED"
      ) {
        navigate("/payment-success/succeedeed");
      }
    } catch (error) {
      // console.log(error);
      // toast.error(error.message);
      // toast.error("Please Fill all Fields");
    }
  }

  function onError(error) {
    // toast.error(error.message);
    toast.error("Please Fill all Fields");
    // Do something with the error from the SDK
  }

  return (
    <PayPalScriptProvider
      options={{
        components: "card-fields,buttons",
        clientId:
          "AUMzonKF_w4Gov_RZ_SmZfCYkStAkghOQSixBNWtYG9FD4FROnsiohAsqiCtgisPUhEGN8U_H2ZIO5l0",
      }}
    >
      <PayPalButtons
        createOrder={createOrder}
        onApprove={onApprove}
        onError={onError}
      />
    </PayPalScriptProvider>
  );
}
