import { useMutation, useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  CustomDateTimePicker,
  CustomRadio,
  CustomTextField,
} from "../../../components/CustomTextField";
import {
  CREATE_GUEST_HOUSE_PRAYER,
  GET_GUEST_HOUSE_PRAYER_SCHEDULE_FOR_USER,
} from "../../../graphql/guest_house";
import PaypalButtonForPrayer from "./PaypalButtonForPrayer";
import { Warning } from "@mui/icons-material";
export default function BookForPrayer() {
  const navigate = useNavigate();

  const { currentUser } = useSelector((state) => state.auth);

  const [step, setStep] = useState(0);

  const [createRecord, { loading }] = useMutation(CREATE_GUEST_HOUSE_PRAYER);

  const lookups = useQuery(GET_GUEST_HOUSE_PRAYER_SCHEDULE_FOR_USER);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: validator,
    defaultValues: {
      user_id: currentUser.id,
      start_date: new Date(),
      end_date: new Date(),
    },
  });

  useEffect(() => {
    setValue("first_name", currentUser.first_name);
    setValue("last_name", currentUser.last_name);
    setValue("email", currentUser.email);
    setValue("phone", currentUser.phone);
  }, []);

  useEffect(() => {
    if (watch("include_pickup_from_airport") || watch("payment_method")) {
      const extra_amount = watch("payment_method") === "Paypal" ? 50 : 0;

      const payment_amount = watch("payment_method") === "Paypal" ? 250 : 5000;

      setValue(
        "payment_amount",
        watch("include_pickup_from_airport") === "Yes"
          ? parseFloat(payment_amount) + parseFloat(extra_amount)
          : parseFloat(payment_amount)
      );
    }
  }, [watch("include_pickup_from_airport"), watch("payment_method")]);

  console.log(errors);

  // useEffect(() => {
  //   const schedule = lookups.data?.guestHousePrayerScheulesForUsers?.find(
  //     (s) => s.id === watch("schedule_id")
  //   );

  //   setValue(
  //     "payment_amount",
  //     watch("payment_method") === "Paypal"
  //       ? schedule?.payment_amount_usd
  //       : schedule?.payment_amount_etb
  //   );
  // }, [watch("payment_method"), watch("schedule_id")]);

  const onSubmit = async (values) => {
    values.include_pickup_from_airport =
      values.include_pickup_from_airport === "Yes";
    delete values.agreement;

    try {
      const { data } = await createRecord({ variables: { input: values } });

      if (data?.createGuestHousePrayer?.data?.checkout_url) {
        window.location = data?.createGuestHousePrayer?.data?.checkout_url;
        // window.open(data?.createGuestHousePrayer?.data?.checkout_url, "_blank");
      }
      reset();

      toast.success("You have Registered for Prayer !", {
        autoClose: 500,
      });
    } catch (error) {
      toast.error(error.message, {
        autoClose: 500,
      });
    }
  };

  return (
    <>
      {" "}
      <div style={{ marginTop: "130px" }}>
        {/* <h2
          style={{
            marginInline: "auto",
            fontSize: "40px",
            textAlign: "center",
          }}
          className="h2 section-subtitle"
        >
          Meet One to One with Man of God
        </h2> */}

        {/* <h2
          style={{
            marginInline: "auto",
            fontSize: "40px",
            textAlign: "center",
          }}
          className="h2 section-subtitle"
        >
          One to One meeting with Man of God
        </h2> */}
      </div>
      <section
        className="section about"
        id="about"
        aria-label="about"
        style={{ marginTop: "100px" }}
      >
        <h2
          style={{
            marginInline: "auto",
            fontSize: "40px",
            textAlign: "center",
          }}
          className="h2 section-subtitle"
        >
          International & National visitor form
        </h2>
        <div
          style={{ marginTop: "50px", fontFamily: "roboto" }}
          className="container"
        >
          <div className="about-content">
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              For Those Who Need Private Praying With Man Of God If you want a
              private prayer, you have to Know and agree with the following
              Steps.
            </p>

            <p style={{ fontSize: "1.75rem" }} className="section-text">
              1st…we encourage you to sow a seed for the work of God according
              to the bible truth.{" "}
            </p>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              2nd…You need to have a face to face prayer line. And hold the
              prayer line paper, which has a request detail on the paper.
            </p>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              3rd...You need to stand in front of your camera clearly to pray
              with the man of God face to face.
            </p>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              4th…we never allow you to give the link for not registered
              individuals after you received the link.
            </p>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              5th…For those who do not believe in God's work and miracles, the
              service you have been given, as a witness to many, will be
              broadcast on our Anointing TV YouTube and Facebook page, and only
              if you agree to this you can have a Private Praying service.
            </p>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              N.B:-- if you agree, we need your quick response that you are
              fully agreed. Then we registered and confirm you the day of
              prayer, as soon as possible.
            </p>

            <div className="d-flex justify-content-center">
              <CustomRadio
                control={control}
                name={"agreement"}
                label={"Agreement"}
                options={[
                  { label: "I Agree", value: "I Agree" },
                  { label: "I do not Agree", value: "I do not Agree" },
                ]}
              />
            </div>

            <div className="wrapper">
              <div className="about-coach">
                <div style={{ margin: "auto" }}>
                  <h3 className="h4 coach-name"></h3>
                </div>
              </div>
            </div>
          </div>

          <div className="about-banner has-after">
            <div className="about-content">
              <p style={{ fontSize: "1.75rem" }} className="section-text">
                ከእግዚአብሄር ሰው ጋር መጸለይ በግል ለሚያስፈልጋቸው የግል ጸሎት ከፈለጉ የሚከተሉትን ደረጃዎች ማወቅ
                እና መስማማት አለብዎት።
              </p>

              <p style={{ fontSize: "1.75rem" }} className="section-text">
                1 ኛ… በመፅሀፍ ቅዱስ እውነት መሰረት ለእግዚአብሄር ስራ አንድ ዘር እንዲዘሩ እናበረታታዎታለን ፡፡
              </p>
              <p style={{ fontSize: "1.75rem" }} className="section-text">
                2 ኛ… ፊት ለፊት የፀሎት መስመር ሊኖርዎት ይገባል ፡፡ እናም በወረቀቱ ላይ የጥያቄ ዝርዝር የያዘውን
                የፀሎት መስመር ወረቀት ይያዙ ፡፡
              </p>
              <p style={{ fontSize: "1.75rem" }} className="section-text">
                3 ኛ ... ፊት ለፊት ከእግዚአብሄር ሰው ጋር ለመጸለይ በግልፅ በካሜራዎ ፊት መቆም ያስፈልግዎታል
                ፡፡
              </p>
              <p style={{ fontSize: "1.75rem" }} className="section-text">
                4 ኛ… ሊንኩን ከተቀበሉ በኋላ ላልተመዘገቡ ግለሰቦች ሊንኩን እንዲሰጡ በጭራሽ አንፈቅድም ፡፡
              </p>
              <p style={{ fontSize: "1.75rem" }} className="section-text">
                5ኛ ...የ እግዚአብሔር ስራን እና ተዓምራቱን ለማያምኑ ሁሉ የተገለገላችሁበትን አገልግሎት ለ ብዙዎች
                ምስክር ይሆን ዘንድ እንደእግዚአብሔር ቃል በ Anointing TV YouTube እና Facebook
                ገፃችን አግልግሎቱን የምናስተላልፍ ሲሆን በዚህ የሚስማሙ ከሆነ ብቻ የግል አገልግሎቱን መገልገል
                እፈልጋለው በሚለው ላይ ምልክት ያድርጉ
              </p>
              <p style={{ fontSize: "1.75rem" }} className="section-text">
                ማስታወሻ: - ከተስማሙ እኛ ሙሉ በሙሉ እንደተስማሙ ፈጣን ምላሽዎን እንፈልጋለን ፡፡ ከዚያም
                የሚፀልዩበትን ቀን በተቻለ ፍጥነት መዝግበን እናረጋግጥልዎታለን፡፡
              </p>

              <div className="d-flex justify-content-center">
                <CustomRadio
                  control={control}
                  name={"agreement"}
                  label={"Agreement"}
                  options={[
                    { label: "ፈቃደኛ ነኝ", value: "I Agree" },
                    { label: "ፈቃደኛ አይደለሁም", value: "I do not Agree" },
                  ]}
                />
              </div>

              <div className="wrapper">
                <div className="about-coach">
                  <div style={{ margin: "auto" }}>
                    <h3 className="h4 coach-name"></h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container mt-5">
        <h2 className="text-center mb-4">Visitors Form</h2>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              {watch("agreement") === "I Agree" && (
                <form
                  className="php-email-form"
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ padding: 0 }}
                >
                  <Alert
                    icon={<Warning />}
                    variant="filled"
                    color="warning"
                    sx={{ mb: 2, fontSize: "1.5rem" }}
                  >
                    Please Try to book, Before a month !
                  </Alert>

                  {step === 0 && (
                    <>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <CustomDateTimePicker
                            control={control}
                            name={"start_date"}
                            label={"From"}
                          />
                        </div>

                        <div className="col-md-6 form-group">
                          <CustomDateTimePicker
                            control={control}
                            name={"end_date"}
                            label={"To"}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <CustomTextField
                            control={control}
                            name={"first_name"}
                            label={"First Name"}
                          />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                          <CustomTextField
                            control={control}
                            name={"last_name"}
                            label={"Last Name"}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6 form-group">
                          <CustomTextField
                            control={control}
                            name={"phone"}
                            label={"Phone"}
                          />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                          <CustomTextField
                            control={control}
                            name={"email"}
                            label={"Email"}
                          />
                        </div>
                      </div>

                      <div className="col-md-12 form-group">
                        <CustomTextField
                          control={control}
                          name={"request_detail"}
                          label={"Request Detail"}
                          multiline
                          rows={4}
                        />
                      </div>
                    </>
                  )}

                  {step === 1 && (
                    <>
                      {" "}
                      <div className="row mt-3">
                        {/* <div className="col-md-6 form-group">
                          <CustomAutoComplete
                            control={control}
                            name={"schedule_id"}
                            label={"Select Schedule"}
                            loading={lookups.loading}
                            options={(
                              lookups.data?.guestHousePrayerScheulesForUsers ||
                              []
                            ).map((s) => ({
                              id: s.id,
                              name:
                                new Date(s.start_time).toLocaleDateString() +
                                " - " +
                                new Date(s.end_time).toLocaleDateString(),
                            }))}
                          />
                        </div> */}
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                          <CustomTextField
                            control={control}
                            name={"payment_method"}
                            label={"Payment Method"}
                            select
                            options={["Local Currency", "Paypal"]}
                          />
                        </div>{" "}
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                          <CustomTextField
                            control={control}
                            name={"include_pickup_from_airport"}
                            label={"Include Transport From Airport"}
                            select
                            options={["Yes", "No"]}
                            disabled={
                              watch("payment_method") === "Local Currency"
                            }
                          />
                        </div>{" "}
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12 form-group">
                          <CustomTextField
                            control={control}
                            name={"payment_amount"}
                            label={"Payment Amount"}
                            type="number"
                            disabled
                            endAdornment={
                              <Typography fontSize={"1.5rem"}>
                                {watch("payment_method") === "Paypal"
                                  ? "USD"
                                  : "ETB"}
                              </Typography>
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="mt-4"></div>

                  {step === 0 ? (
                    <button
                      type={"button"}
                      className="btn btn-primary btn-block "
                      style={{
                        minWidth: "100%",
                        height: "5.5rem",
                        backgroundColor: "#ffc439",
                        border: "none",
                        borderRadius: "5px",
                        fontSize: "1.85rem",
                        lineHeight: 1,
                        color: "#003087",
                        fontWeight: "bold",
                        marginBottom: "9px",
                      }}
                      onClick={async () => {
                        const isValid = await trigger(["request_detail"]);
                        if (isValid) setStep(1);
                      }}
                    >
                      Next
                    </button>
                  ) : (
                    currentUser.id &&
                    (watch("payment_method") === "Paypal" ? (
                      <PaypalButtonForPrayer watch={watch} trigger={trigger} />
                    ) : (
                      <div className="d-flex justify-content-center">
                        <button
                          type={loading ? "button" : "submit"}
                          className="btn btn-primary btn-block "
                          style={{
                            minWidth: "100%",
                            height: "5.5rem",
                            backgroundColor: "#ffc439",
                            border: "none",
                            borderRadius: "5px",
                            fontSize: "1.85rem",
                            lineHeight: 1,
                            color: "#003087",
                            fontWeight: "bold",
                            marginBottom: "9px",
                          }}
                        >
                          {loading ? "Loading..." : "Submit"}
                        </button>
                      </div>
                    ))
                  )}

                  {!currentUser.id && (
                    <button
                      type={"button"}
                      className="btn btn-primary btn-block "
                      style={{
                        minWidth: "100%",
                        height: "5.5rem",
                        backgroundColor: "#ffc439",
                        border: "none",
                        borderRadius: "5px",
                        fontSize: "1.85rem",
                        lineHeight: 1,
                        color: "#003087",
                        fontWeight: "bold",
                        marginBottom: "9px",
                      }}
                      onClick={() => navigate("/sign-up")}
                    >
                      Please Sign Up First
                    </button>
                  )}

                  <div className="mt-4" style={{ height: "15rem" }}></div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const validator = yupResolver(
  Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    payment_amount: Yup.number()
      .positive("Payment amount must be positive")
      .required("Payment amount is required"),
    payment_method: Yup.string().required("Payment method is required"),
    phone: Yup.string().required("Phone number is required"),
    request_detail: Yup.string().required("Request detail is required"),
    // schedule_id: Yup.number().required("Schedule ID is required"),
    include_pickup_from_airport: Yup.string().when("payment_method", {
      is: (value) => value === "Paypal",
      then: (schema) => schema.required("required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    start_date: Yup.date().required("Start date is required"),
    end_date: Yup.date().required("End date is required"),
  })
);
