import { useQuery } from "@apollo/client";
import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { GET_BIBLE_STUDY_SESSIONS_FOR_USERS } from "../../../graphql/bible_study";

export default function BibleStudy() {
  const navigate = useNavigate();

  const { currentUser } = useSelector((state) => state.auth);

  const { data, loading } = useQuery(GET_BIBLE_STUDY_SESSIONS_FOR_USERS);

  return (
    <>
      <section
        className="section about"
        id="about"
        aria-label="about"
        style={{ marginTop: "120px" }}
      >
        <h2
          style={{
            marginInline: "auto",
            fontSize: "40px",
            textAlign: "center",
          }}
          className="h2 section-subtitle"
        >
          Teaching - Prophetic Retreat - Zoom Online Meeting
        </h2>
        <div
          style={{ marginTop: "50px", fontFamily: "roboto" }}
          className="container"
        >
          <div className="about-content">
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              The prophetic retreat can be defined most simply as a definite
              time (from a few hours in length to a month) spent away from one's
              normal life for the purpose of reconnecting, usually in prayer,
              with God ,and words of revelation.
            </p>

            <div className="wrapper">
              <div className="about-coach">
                <div style={{ margin: "auto" }}>
                  <h3 className="h4 coach-name"></h3>
                </div>
              </div>
            </div>
          </div>

          <div className="about-content">
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              ትንቢታዊው ማፈግፈግ በጣም በቀላሉ ሊገለጽ የሚችለው በተወሰነ ጊዜ ነው። (ከጥቂት ሰአታት ርዝማኔ እስከ
              አንድ ወር ድረስ) ከመደበኛው ህይወት ርቆ የሚቆይበት አላማ ከእግዚአብሄር ጋር አብዛኛውን ጊዜ በጸሎት
              ለመገናኘት የሚደረግ ውሳኔ እና የቃል መገለጥ ነው።
            </p>

            <div className="wrapper">
              <div className="about-coach">
                <div style={{ margin: "auto" }}>
                  <h3 className="h4 coach-name"></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ marginTop: "50px" }} className="package" id="package">
        <div className="container">
          <p
            className="section-subtitle"
            style={{ textAlign: "center", margin: "auto" }}
          >
            Hosted By : Prophet Deresse Lakew
          </p>

          <br />

          {loading ? (
            <Skeleton height={"10rem"} />
          ) : (
            <Stack spacing={4} className="package-list">
              {data?.bibleStudySessionsForUsers?.map((bs) => (
                <>
                  <Grid container direction={"row"} className="package-card">
                    <Grid
                      item
                      lg={2}
                      xs={2}
                      sx={{
                        fontSize: "9rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                        fontStyle: "italic",
                        color: "#0975c4",
                      }}
                    >
                      {/* <figure className="card-banner" style={{ flex: 1 }}>
                        <img
                          src={bs?.picture}
                          alt="Experience The Great Holiday On Beach"
                          loading="lazy"
                        />
                      </figure> */}
                      Z <br /> O <br /> O <br /> M <br />
                      <img
                        alt="zoom"
                        src="/assets/images/zoom.png"
                        style={{ width: "100%" }}
                      />
                    </Grid>

                    <Grid item lg={10} xs={10}>
                      <div className="card-content" style={{ flex: 2 }}>
                        <Box width={"100%"}>
                          <h3
                            className="h3 card-title"
                            style={{
                              margin: 0,
                              padding: 0,
                              textAlign: "center",
                              marginRight: "150px",
                            }}
                          >
                            {bs.title}
                          </h3>

                          <p
                            className=""
                            style={{
                              overflow: "hidden",
                              maxWidth: "100%",
                              fontSize: "1.5rem",
                            }}
                          >
                            <pre
                              style={{
                                fontSize: "inherit",
                                fontFamily: "inherit",
                              }}
                            >
                              {" "}
                              {bs.description}
                            </pre>
                          </p>

                          <Typography fontSize={"1.5rem"} color={"black"}>
                            {/* Date: {new Date(bs.date).toLocaleDateString()} */}
                            Every {getDayOfWeek(new Date(bs?.date))}
                          </Typography>

                          <Stack
                            direction={"row"}
                            flexWrap={"wrap"}
                            spacing={2}
                          >
                            <ul
                              className="card-meta-list"
                              style={{ margin: 2, padding: 0 }}
                              // style={{ display: "flex", flexWrap: "wrap" }}
                            >
                              <li className="card-meta-item">
                                <div className="meta-box">
                                  <ion-icon name="time"></ion-icon>

                                  <p className="text">
                                    {formatTimeInTimeZone(
                                      new Date(bs.start_time),
                                      timeZones.washington
                                    )}
                                  </p>
                                </div>
                              </li>

                              <li className="card-meta-item">
                                <div className="meta-box">
                                  <ion-icon name="location"></ion-icon>

                                  <p className="text">Washington DC</p>
                                </div>
                              </li>
                            </ul>
                            <ul
                              className="card-meta-list"
                              style={{ margin: 2, padding: 0 }}
                            >
                              <li className="card-meta-item">
                                <div className="meta-box">
                                  <ion-icon name="time"></ion-icon>

                                  <p className="text">
                                    {formatTimeInTimeZone(
                                      new Date(bs.start_time),
                                      timeZones.toronto
                                    )}
                                  </p>
                                </div>
                              </li>

                              <li className="card-meta-item">
                                <div className="meta-box">
                                  <ion-icon name="location"></ion-icon>

                                  <p className="text">Toronto</p>
                                </div>
                              </li>
                            </ul>
                            <ul
                              className="card-meta-list"
                              style={{ margin: 2, padding: 0 }}
                            >
                              <li className="card-meta-item">
                                <div className="meta-box">
                                  <ion-icon name="time"></ion-icon>

                                  <p className="text">
                                    {formatTimeInTimeZone(
                                      new Date(bs.start_time),
                                      timeZones.ethiopia
                                    )}
                                  </p>
                                </div>
                              </li>

                              <li className="card-meta-item">
                                <div className="meta-box">
                                  <ion-icon name="location"></ion-icon>

                                  <p className="text">Ethiopia</p>
                                </div>
                              </li>
                            </ul>
                          </Stack>
                        </Box>
                      </div>{" "}
                      <Stack
                        direction={"row"}
                        pb={1}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            if (currentUser.id) {
                              navigate(
                                "apply/" +
                                  bs.title.replaceAll(" ", "-").toLowerCase(),
                                {
                                  state: { record: bs },
                                }
                              );
                            } else {
                              toast.error("Please Sign Up First !");
                              navigate("/sign-up");
                            }
                          }}
                        >
                          Join Us
                        </button>
                      </Stack>
                    </Grid>

                    {/* <Grid item lg={2}>
                      <div className="card-price" style={{ height: "100%" }}>
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            if (currentUser.id) {
                              navigate(
                                "apply/" +
                                  bs.title.replaceAll(" ", "-").toLowerCase(),
                                {
                                  state: { record: bs },
                                }
                              );
                            } else {
                              toast.error("Please Login First !");
                              navigate("/login");
                            }
                          }}
                        >
                          Join Us
                        </button>
                      </div>{" "}
                    </Grid> */}
                  </Grid>
                </>
              ))}{" "}
            </Stack>
          )}
        </div>
      </section>
    </>
  );
}

const timeZones = {
  toronto: "America/Toronto",
  washington: "America/New_York",
  ethiopia: "Africa/Addis_Ababa",
};

// Convert date to different time zones
const formatTimeInTimeZone = (date, timeZone) => {
  return new Intl.DateTimeFormat("en-US", {
    timeZone: timeZone,
    hour: "numeric",
    minute: "numeric",
    hour12: true, // Use 12-hour format
  }).format(date);
};

function getDayOfWeek(date) {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return daysOfWeek[date.getDay()];
}
