import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CustomTextField } from "../../../components/CustomTextField";
import { APPLY_FOR_BIBLE_STUDY_ } from "../../../graphql/bible_study";
import PaypalButtonForBS from "./PaypalButtonForBS";
import { Typography } from "@mui/material";
export default function ApplyToJoinBS({}) {
  const { currentUser } = useSelector((state) => state.auth);

  const [createRecord, { loading }] = useMutation(APPLY_FOR_BIBLE_STUDY_);

  const { state } = useLocation();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: validator,
    defaultValues: {},
  });

  useEffect(() => {
    setValue("bible_study_session_id", state.record.id);
    setValue("payment_amount", state.record.payment_amount);
    setValue("first_name", currentUser.first_name);
    setValue("last_name", currentUser.last_name);
    setValue("email", currentUser.email);
    setValue("phone", currentUser.phone);
  }, [state.record]);

  useEffect(() => {
    if (watch("payment_method") === "Paypal") {
      setValue("payment_amount", state?.record?.payment_amount_usd);
    } else if (watch("payment_method") === "Local Currency") {
      setValue("payment_amount", state?.record?.payment_amount_etb);
    } else {
      setValue("payment_amount", "");
    }
  }, [watch("payment_method")]);

  const onSubmit = async (values) => {
    try {
      const { data } = await createRecord({ variables: { input: values } });

      if (data?.applyForBibleStudy?.data?.checkout_url) {
        window.location = data?.applyForBibleStudy?.data?.checkout_url;
        // window.open(data?.applyForBibleStudy?.data?.checkout_url, "_blank");
      }
      reset();

      toast.success("You have Registered to Prophetic School Session !", {
        autoClose: 500,
      });
    } catch (error) {
      toast.error(error.message, {
        autoClose: 500,
      });
    }
  };

  return (
    <>
      {" "}
      <section
        className="section about"
        id="about"
        aria-label="about"
        style={{ marginTop: "120px" }}
      >
        <h2
          style={{
            marginInline: "auto",
            fontSize: "40px",
            textAlign: "center",
          }}
          className="h2 section-subtitle"
        >
          Prephetic School Terms and Conditions
        </h2>
        <div
          style={{ marginTop: "50px", fontFamily: "roboto" }}
          className="container"
        >
          <div className="about-content">
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              For this educational and anointing and power sharing 18 hour
              training you must meet the following conditions:-
              <br />
            </p>

            <p>
              1st/ Each student must send proof of registration by sending $100
              usd via remittance option.
            </p>
            <p>
              2/ You should prepare equipments needed for education like pen and
              notebook
            </p>
            <p>
              3/ Because the time is convenient according to the situation of
              each country You will have to choose and notify them between
              Monday and Friday.
            </p>
            <p>
              4/ Since the course is given directly to registered students only,
              you will need to prepare a mobile phone or laptop and a good
              internet connection.
            </p>

            <p>
              Note: When the lesson is over gtaduation ceremony, spiritual
              material and international training certificate is issued.
            </p>

            <p>
              A gift from the man of God Robes, Anointing Oil and a personal
              telephone number of prophet is given for prayer and any counseling
              services.
            </p>

            <h2
              style={{
                marginInline: "auto",
                fontSize: "25px",
                textAlign: "center",
              }}
              className="h2 section-subtitle"
            >
              --
            </h2>
            <p>
              <b>ANOINTING TV TEAM</b>
            </p>

            <div className="wrapper">
              <div className="about-coach">
                <div style={{ margin: "auto" }}>
                  <h3 className="h4 coach-name"></h3>
                </div>
              </div>
            </div>
          </div>

          <div className="about-content">
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              ለዚህ የትምህርት እና ቅባት እንዲሁም ሃይል የመካፈል የ18 ሰአት ስልጠና መሙዎላት
              የሚገባቸው....ሁኔታዎች
              <br />
            </p>
            <p>
              1ኛ/ እያንዳንዱ ተማሪ የሪጅስትሬሽን $100 usd በሚችለው የገንዘብ መላኪያ አማራጭ ልኮ ያስገባበትን
              ማስረጃ መላክ ይኖርበታል።
            </p>
            <p>
              2/ ለመማርያ የሚያስፈልጉ ማለትም መጽሓፍ ቅዱስ ማስታወሻ ደብተር እስክርቢቶ ማዘጋጀት ይኖርበታል።
            </p>
            <p>
              3/ እንደየ ሃገሩ ሁኔታ ሰአት ስለሚመቻች ከሰኞ እና ከአርብ ቀን መምረጥ እና ማሳወቅ ይኖርባቸዎል።
            </p>
            <p>
              4/ትምህርቱ በእግዚዚአብሔር ሰው ነቢይ ደረሰ ላቀው በቀጥታ ለተመዝጋቢ ተማሪዎች ብቻ
              ስለሚሰጥ፤ሞባይል፤ወይም ላፕቶብ እና ጥሩ የኢንተርኔት መስመር ማዘጋጀት ይኖርባቸዎል።
            </p>
            <p>
              ማስታወሻ፡ ትምህርቱ ሲጠናቀቅ የምርቃት ስነስርአት እና ስፒሪቹዋልማቴሪያል፤ አለም አቀፍ የስልጠና
              ሰርተፊኬት ይሰጣል።
            </p>
            <p>
              ከእግዚአብሔር ሰው የሚበረከት መጎናፀፊያ፤አኖይንቲንግ ኦይል እና ለፀሎት እና ለማንኛውም የምክር
              አገልግሎት የነቢይ ደረሰ ላቀዉ የግል ስልክ ይሰጣል።
            </p>
            <h2
              style={{
                marginInline: "auto",
                fontSize: "25px",
                textAlign: "center",
              }}
              className="h2 section-subtitle"
            >
              --
            </h2>{" "}
            <p>
              <b>ANOINTING TV TEAM</b>
            </p>
            <div className="wrapper">
              <div className="about-coach">
                <div style={{ margin: "auto" }}>
                  <h3 className="h4 coach-name"></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div style={{ marginTop: "180px" }}>
        <h2
          style={{
            marginInline: "auto",
            fontSize: "40px",
            textAlign: "center",
          }}
          className="h2 section-subtitle"
        >
          Join Prophetic School <br />
          {state.record.title}
        </h2>
      </div>
      <div className="container ">
        {/* <h2 className="text-center mb-4">Donate and Partner with Us</h2> */}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <form
                className="php-email-form"
                onSubmit={handleSubmit(onSubmit)}
                style={{ padding: 0 }}
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <CustomTextField
                      control={control}
                      name={"first_name"}
                      label={"First Name"}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <CustomTextField
                      control={control}
                      name={"last_name"}
                      label={"Last Name"}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6 form-group">
                    <CustomTextField
                      control={control}
                      name={"phone"}
                      label={"Phone"}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <CustomTextField
                      control={control}
                      name={"email"}
                      label={"Email"}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <CustomTextField
                      control={control}
                      name={"payment_method"}
                      label={"Payment Method"}
                      select
                      options={["Local Currency", "Paypal"]}
                    />
                  </div>{" "}
                  <div className="col-md-6 form-group">
                    <CustomTextField
                      control={control}
                      name={"payment_amount"}
                      label={"Payment Amount"}
                      type="number"
                      disabled
                      endAdornment={
                        <Typography fontSize={"1.5rem"}>
                          {watch("payment_method") === "Paypal" ? "USD" : "ETB"}
                        </Typography>
                      }
                    />
                  </div>
                </div>

                <div className="mt-4"></div>

                {watch("payment_method") === "Paypal" ? (
                  <PaypalButtonForBS watch={watch} trigger={trigger} />
                ) : (
                  <div className="d-flex justify-content-center">
                    <button
                      type={loading ? "button" : "submit"}
                      className="btn btn-primary btn-block "
                      style={{
                        minWidth: "100%",
                        height: "5.5rem",
                        backgroundColor: "#ffc439",
                        border: "none",
                        borderRadius: "5px",
                        fontSize: "1.85rem",
                        lineHeight: 1,
                        color: "#003087",
                        fontWeight: "bold",
                        marginBottom: "9px",
                      }}
                    >
                      {loading ? "Loading..." : "Submit"}
                    </button>
                  </div>
                )}

                <div className="mt-4" style={{ height: "15rem" }}></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const validator = yupResolver(
  Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    payment_method: Yup.string().required("Payment method is required"),
    payment_amount: Yup.number()
      .positive("Payment amount must be positive")
      .required("Payment amount is required"),
    phone: Yup.string().required("Phone number is required"),
  })
);
