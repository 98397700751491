import { useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import { GET_BLOGS } from "../../../graphql/blog";
import { Skeleton } from "@mui/material";

export default function Blog() {
  const { data, loading, refetch } = useQuery(GET_BLOGS);

  return (
    <>
      <section
        className="section blog"
        id="blog"
        aria-label="blog"
        style={{ marginTop: "10rem" }}
      >
        <div className="container">
          <h2 className="h2 section-title text-center">Latest Blog Feed</h2>

          <ul className="blog-list row">
            {loading ? (
              <Skeleton height={"15rem"} />
            ) : (
              data?.blogs?.map((blog) => (
                <div className="p-3 col-lg-4">
                  <div className="blog-card ">
                    <div
                      className="card-banner img-holder"
                      style={{ "--width": "440", "--height": "270" }}
                    >
                      <img
                        src={blog?.image}
                        width="440"
                        height="270"
                        loading="lazy"
                        alt="Going to the Church for the first time"
                        className="img-cover"
                      />
                    </div>

                    <div className="card-content">
                      <h3 className="h3">
                        <Link
                          className="card-title"
                          to={
                            "/blog/" +
                            blog?.title.toLowerCase().replaceAll(" ", "-")
                          }
                          state={{ blog: blog }}
                        >
                          {blog?.title}
                        </Link>
                      </h3>

                      <p className="card-text">{blog?.excerpt}</p>

                      <Link
                        style={{ textDecoration: "none" }}
                        className="btn-link has-before"
                        to={
                          "/blog/" +
                          blog?.title.toLowerCase().replaceAll(" ", "-")
                        }
                        state={{ blog: blog }}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            )}
            {/* @foreach ($featured as $post) */}

            {/* @endforeach */}
          </ul>
        </div>
      </section>
    </>
  );
}
