import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CustomTextField } from "../../../components/CustomTextField";
import { CREATE_PARTNERSHIP } from "../../../graphql/partnership";
import OneTimePaypalButton from "../Partnership/OneTimePaypalButton";

export default function Give() {
  const { currentUser } = useSelector((state) => state.auth);

  const [foreignOptions, setForeignOptions] = useState([
    { value: 10, label: "USD" },
    { value: 30, label: "USD" },
    { value: 50, label: "USD" },
    { value: 100, label: "USD" },
    { value: 200, label: "USD" },
    { value: 500, label: "USD" },
    { value: 1000, label: "USD" },
    { value: "other", label: "" },
  ]);

  const [localOptions, setLocalOptions] = useState([
    { value: 100, label: "ETB" },
    { value: 300, label: "ETB" },
    { value: 500, label: "ETB" },
    { value: 1000, label: "ETB" },
    { value: 2000, label: "ETB" },
    { value: 5000, label: "ETB" },
    { value: 10000, label: "ETB" },
    { value: "other", label: "" },
  ]);

  const [createPartnership, { loading }] = useMutation(CREATE_PARTNERSHIP);

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: validator,
    defaultValues: {
      plan: "Monthly",
      currency: "ETB",
    },
  });
  console.log(errors);

  useEffect(() => {
    setValue("first_name", currentUser?.first_name);
    setValue("last_name", currentUser?.last_name);
    setValue("email", currentUser?.email);
    setValue("phone", currentUser?.phone);
  }, []);

  useEffect(() => {
    if (watch("amount_options") && watch("amount_options") !== "other") {
      setValue("amount", parseFloat(watch("amount_options")));
    } else {
      // setValue("amount", 0);
    }
  }, [watch("amount_options")]);

  const onSubmit = async (values) => {
    try {
      delete values.amount_options;
      const { data } = await createPartnership({
        variables: {
          input: {
            ...values,
          },
        },
      });

      if (data?.createPartnership?.status === "success") {
        window.location = data?.createPartnership?.data?.checkout_url;
      }
      reset();
      toast.success("You have Successfully registered for partnership !", {
        autoClose: 500,
      });
    } catch (error) {
      toast.error("Internal Server Error", {
        autoClose: 500,
      });
    }
  };

  return (
    <>
      {" "}
      <section
        className="section about"
        id="about"
        aria-label="about"
        style={{ marginTop: "100px" }}
      >
        <h2
          style={{
            marginInline: "auto",
            fontSize: "40px",
            textAlign: "center",
          }}
          className="h2 section-subtitle"
        >
          Give / ይስጡ{" "}
        </h2>
        <div
          style={{ marginTop: "50px", fontFamily: "roboto" }}
          className="container"
        >
          <div className="about-content">
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              {/* Give <br /> */}
              When we give, we will be equipped to be more and more generous.
            </p>

            <p style={{ fontSize: "1.75rem" }} className="section-text">
              In the same way, he will provide and increase your resources and
              then produce a great harvest of generosity in you. Yes, you will
              be enriched in every way so that you can always be generous.{" "}
              <br /> 2 Corinthians 9:10-11
            </p>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              It’s a basic biblical principle of stewardship—when we are
              faithful with a little bit, God will trust us with more.
              Generosity causes God to invest more in us because he knows we
              will continue to be generous. It’s a cycle of God blessing us so
              we can give that goes on and on and on and on…
            </p>

            <h2
              style={{
                marginInline: "auto",
                fontSize: "25px",
                textAlign: "center",
              }}
              className="h2 section-subtitle"
            >
              --
            </h2>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              {/* ስጡ <br />  */}
              ስንሰጥ የበለጠ ለጋስ ለመሆን እንዘጋጃለን።
              <br />
            </p>
            <p style={{ fontSize: "1.75rem" }} className="section-text">
              በተመሳሳይ መንገድ, እሱ ያቀርብልዎታል እና ሃብቶቻችሁን ያሳድጋል, ከዚያም በእናንተ ውስጥ ትልቅ
              የልግስና ምርት ያፈራል. አዎ፣ ሁል ጊዜ ለጋስ እንድትሆን በሁሉም መንገድ ትበለጽጋለህ። <br /> 2ኛ
              ቆሮንቶስ 9፡10-11
            </p>

            <p style={{ fontSize: "1.75rem" }} className="section-text">
              የባለአደራነት መሰረታዊ መጽሐፍ ቅዱሳዊ መርህ ነው - በጥቂቱ ታማኝ ስንሆን እግዚአብሔር በብዙ ያምነናል።
              ልግስና እግዚአብሔር ብዙ መዋዕለ ንዋይ እንዲያፈስብን ያደርጋል ምክንያቱም ለጋስ መሆናችንን እንደምንቀጥል
              ስለሚያውቅ ነው። የሚቀጥል እና የሚቀጥል ስጦታ የእግዚአብሔርን የሚቀጥል በረከት እንዲቀጥል በማድረግ
              እንድንሰጥ እግዚአብሔር የመባረክን ዑደት ያስቀጥላል።
            </p>

            <div className="wrapper">
              <div className="about-coach">
                <div style={{ margin: "auto" }}>
                  <h3 className="h4 coach-name"></h3>
                </div>
              </div>
            </div>
          </div>

          <div className="about-banner has-after">
            <img
              src="/assets/images/albastros.jpg"
              width="460"
              height="400"
              loading="lazy"
              alt="about banner"
              className="w-100"
            />

            <img
              alt="fjdkls"
              src="./assets/images/"
              width="650"
              height="154"
              loading="lazy"
              className="abs-img w-100"
            />
          </div>
        </div>
      </section>
      <div className="container ">
        {/* <h2 className="text-center mb-4">Donate and Partner with Us</h2> */}
        <div style={{ marginTop: "140px" }}>
          <h2
            style={{
              marginInline: "auto",
              fontSize: "40px",
            }}
            className="h2 section-subtitle"
          >
            Give to church
          </h2>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <form
                className="php-email-form"
                onSubmit={handleSubmit(onSubmit)}
                style={{ padding: 0 }}
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <CustomTextField
                      control={control}
                      name={"first_name"}
                      label={"First Name"}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <CustomTextField
                      control={control}
                      name={"last_name"}
                      label={"Last Name"}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6 form-group">
                    <CustomTextField
                      control={control}
                      name={"phone"}
                      label={"Phone"}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <CustomTextField
                      control={control}
                      name={"email"}
                      label={"Email"}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6 form-group">
                    <CustomTextField
                      control={control}
                      name={"partnership_type"}
                      label={"Donation Type"}
                      select
                      options={["One Time", "Recurring"]}
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <CustomTextField
                      control={control}
                      name={"partnership_plan"}
                      label={"Partnership Plan"}
                      select
                      options={[
                        "Every Month",
                        "Every 3 Month",
                        "Every 6 Month",
                        "Every Year",
                      ]}
                      disabled={watch("partnership_type") === "One Time"}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <CustomTextField
                      control={control}
                      name={"payment_method"}
                      label={"Payment Method"}
                      select
                      options={["Local Currency", "Paypal"]}
                    />
                  </div>{" "}
                  <div className="col-md-6 form-group">
                    <CustomTextField
                      control={control}
                      name={"amount_options"}
                      label={"Choose Amount"}
                      select
                      options={(watch("payment_method") === "Paypal"
                        ? foreignOptions
                        : localOptions
                      ).map((option) => ({
                        value: option.value,
                        label: option.value + " " + option.label,
                      }))}
                    />
                  </div>
                  {watch("amount_options") === "other" && (
                    <div className="col-md-12 form-group">
                      <CustomTextField
                        control={control}
                        name={"amount"}
                        label={"Enter Amount"}
                        type="number"
                        endAdornment={
                          <Typography fontSize={"1.5rem"}>
                            {watch("payment_method") === "Paypal"
                              ? "USD"
                              : "ETB"}
                          </Typography>
                        }
                      />
                    </div>
                  )}
                </div>

                <div className="form-group mt-3">
                  <CustomTextField
                    control={control}
                    name={"church_name"}
                    label={"Name of Your Church"}
                  />
                </div>
                <div className="form-group mt-3">
                  <CustomTextField
                    control={control}
                    name={"address"}
                    label={"Address"}
                    multiline
                    rows={1}
                  />{" "}
                </div>
                <div className="form-group mt-3">
                  <CustomTextField
                    control={control}
                    name={"additional_message"}
                    label={"Additional Message"}
                    multiline
                    rows={3}
                  />
                </div>

                <div className="mt-4"></div>

                {watch("payment_method") === "Paypal" ? (
                  // watch("partnership_type") === "One Time" ? (

                  // ) : (
                  //   <RecurringPaypalButton watch={watch} trigger={trigger} />
                  // )

                  <OneTimePaypalButton watch={watch} trigger={trigger} />
                ) : (
                  <div className="d-flex justify-content-center">
                    <button
                      type={loading ? "button" : "submit"}
                      className="btn btn-primary btn-block "
                      style={{
                        minWidth: "100%",
                        height: "5.5rem",
                        backgroundColor: "#ffc439",
                        border: "none",
                        borderRadius: "5px",
                        fontSize: "1.85rem",
                        lineHeight: 1,
                        color: "#003087",
                        fontWeight: "bold",
                        marginBottom: "9px",
                      }}
                    >
                      {loading ? "Loading..." : "Submit"}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const validator = yupResolver(
  Yup.object().shape({
    first_name: Yup.string().required("First Name is required!"),
    last_name: Yup.string().required("Last Name is required!"),
    phone: Yup.string().required("Phone is required!"),
    email: Yup.string()
      .email("Enter a Valid Email!")
      .required("Email is required!"),
    partnership_type: Yup.string().required("partnership Type is required!"),
    partnership_plan: Yup.string().when("partnership_type", {
      is: (value) => value === "Recurring",
      then: (schema) => schema.required("Partnership Plan is required!"),
      otherwise: (schema) => schema.notRequired(),
    }),
    amount_options: Yup.string().required("Amount is required!"),
    amount: Yup.number().when("amount_options", {
      is: (value) => value === "other",
      then: (schema) => schema.min(0).required("Amount is required!"),
      otherwise: (schema) => schema.notRequired(),
    }),
    payment_method: Yup.string().required("Payment Method is required!"),
    church_name: Yup.string().required("Church Name is is required!"),
    address: Yup.string().required("Address is required!"),
    additional_message: Yup.string().notRequired(),
  })
);
