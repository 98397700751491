import { useQuery } from "@apollo/client";
import { PlayCircle } from "@mui/icons-material";
import { IconButton, Skeleton } from "@mui/material";
import React from "react";
import { GET_SERVICES_FOR_USERS } from "../../../graphql/services";

export default function Services() {
  const services = [
    {
      title: "Preaching Time",
      links: [
        {
          title: "Preaching Time",
          sub_title: "Preaching Time",
          link: "https://www.youtube.com/embed/w936n8P65Qc",
        },
        {
          title: "Preaching Time",
          sub_title: "Preaching Time",
          link: "https://www.youtube.com/embed/9c66MGSZRg4",
        },
      ],
    },
    {
      title: "Deliverence Time",
      links: [
        {
          title: "Preaching Time",
          sub_title: "Preaching Time",
          link: "https://www.youtube.com/embed/24afpNGy7k0",
        },
        {
          title: "Preaching Time",
          sub_title: "Preaching Time",
          link: "https://www.youtube.com/embed/JvvC4Eg2ZsI",
        },
      ],
    },
    {
      title: "Prophecy Time",
      links: [
        {
          title: "Preaching Time",
          sub_title: "Preaching Time",
          link: "https://www.youtube.com/embed/Q_dtSl8_CaQ",
          // https://www.youtube.com/watch?v=Q_dtSl8_CaQ
        },
        {
          title: "Preaching Time",
          sub_title: "Preaching Time",
          link: "https://www.youtube.com/embed/JvvC4Eg2ZsI",
        },
      ],
    },

    {
      title: "Healing Time",
      links: [
        {
          title: "Preaching Time",
          sub_title: "Preaching Time",
          link: "https://www.youtube.com/embed/csIUN9Lfi0o",
          // https://www.youtube.com/watch?v=csIUN9Lfi0o
        },
        {
          title: "Preaching Time",
          sub_title: "Preaching Time",
          link: "https://www.youtube.com/embed/8XHClB3lu4Y",
        },
      ],
    },
    {
      title: "Miracle Time",
      links: [
        {
          title: "Preaching Time",
          sub_title: "Preaching Time",
          link: "https://www.youtube.com/embed/jvbsyVdaYGU",
        },
        {
          title: "Preaching Time",
          sub_title: "Preaching Time",
          link: "https://www.youtube.com/embed/OqlfSgQr2P8",
        },
      ],
    },
    {
      title: "Testimony Time",
      links: [
        {
          title: "Preaching Time",
          sub_title: "Preaching Time",
          link: "https://www.youtube.com/embed/zIX747G-q5w",
          // https://www.youtube.com/watch?v=zIX747G-q5w
        },
        {
          title: "Preaching Time",
          sub_title: "Preaching Time",
          link: "https://www.youtube.com/embed/rvM3n02k1Dc",
        },
      ],
    },
    {
      title: "Worship Time",
      links: [
        {
          title: "Preaching Time",
          sub_title: "Preaching Time",
          link: "https://www.youtube.com/embed/C-NZBjZME30",
        },
        {
          title: "Preaching Time",
          sub_title: "Preaching Time",
          link: "https://www.youtube.com/embed/Y3PvxpqpbXQ",
        },
      ],
    },
  ];

  const { data, loading } = useQuery(GET_SERVICES_FOR_USERS);
  return (
    <>
      <section style={{ marginTop: "30px" }} className="section"></section>
      <h2 style={{ textAlign: "center" }} className="h2 section-title">
        Check Our Youtube Channel
      </h2>

      {loading ? (
        <Skeleton height={"20rem"} />
      ) : (
        data?.serviceCategoryForUsers?.map((cat) => (
          <section
            style={{ marginTop: "-30px" }}
            id="youtube"
            className="section about"
            aria-label="about"
          >
            <h2
              style={{
                marginBottom: "20px",
                alignItems: "center",
                marginInline: "auto",
              }}
              className="section-subtitle d-flex"
            >
              <a href={cat.playlist_link} target="_blank" rel="noreferrer">
                {" "}
                <IconButton size="large">
                  <PlayCircle sx={{ fontSize: "3.5rem", color: "#f19700" }} />
                </IconButton>{" "}
                {cat.title}
              </a>{" "}
            </h2>

            <div className="container ">
              {cat?.services?.map((service) => (
                <div
                  style={{ textAlign: "center" }}
                  className="about-banner has-after"
                >
                  <iframe
                    style={{ marginInline: "auto" }}
                    width="460"
                    height="280"
                    src={
                      "https://www.youtube.com/embed/" +
                      getYouTubeID(service?.youtube_link)
                    }
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              ))}
            </div>
          </section>
        ))
      )}

      {/* {services.map((service) => (
        <section
          style={{ marginTop: "-30px" }}
          id="youtube"
          className="section about"
          aria-label="about"
        >
          <h2
            style={{
              marginBottom: "20px",
              alignItems: "center",
              marginInline: "auto",
            }}
            className="section-subtitle"
          >
            {service.title}
          </h2>

          <div className="container ">
            {service.links.map((link) => (
              <div
                style={{ textAlign: "center" }}
                className="about-banner has-after"
              >
                <iframe
                  style={{ marginInline: "auto" }}
                  width="460"
                  height="280"
                  src={link.link}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            ))}
          </div>
        </section>
      ))} */}
      <div className="d-flex justify-content-center">
        <a
          style={{
            marginInline: "auto",
            marginTop: "15px",
            fontWeight: "bold",
          }}
          href="https://www.youtube.com/@ANOINTINGTVCHANNEL"
          className="btn btn-primary"
          target="_blank"
          rel="noreferrer"
        >
          Watch More
        </a>
      </div>

      {/* <package-component></package-component> */}

      {/* <section style={{ marginTop: "30px" }} className="package" id="package">
        <div className="container">
          <p className="section-subtitle"></p>

          <h2 className="h2 section-title">Join Our Zoom Meetings.</h2>

          <ul className="package-list">
            <li>
              <div className="package-card">
                <figure className="card-banner">
                  <img
                    src="./assets/images/zoom.jpg"
                    alt="Experience The Great Holiday On Beach"
                    loading="lazy"
                  />
                </figure>

                <div className="card-content">
                  <h3 className="h3 card-title">Come and Join Our Zoom</h3>

                  <p className="card-text">
                    ''Prophet Derese Lakew is inviting you to a scheduled Zoom
                    meeting Preaching,deliverance and prophecy time. Come and be
                    part of Gods blessing.''
                  </p>

                  <ul className="card-meta-list">
                    <li className="card-meta-item">
                      <div className="meta-box">
                        <ion-icon name="time"></ion-icon>

                        <p className="text"> 3:00 PM</p>
                      </div>
                    </li>

                    <li className="card-meta-item">
                      <div className="meta-box">
                        <ion-icon name="location"></ion-icon>

                        <p className="text">Washington DC</p>
                      </div>
                    </li>
                  </ul>
                  <ul className="card-meta-list">
                    <li className="card-meta-item">
                      <div className="meta-box">
                        <ion-icon name="time"></ion-icon>

                        <p className="text">3:00 AM</p>
                      </div>
                    </li>

                    <li className="card-meta-item">
                      <div className="meta-box">
                        <ion-icon name="location"></ion-icon>

                        <p className="text">Toronto</p>
                      </div>
                    </li>
                  </ul>
                  <ul className="card-meta-list">
                    <li className="card-meta-item">
                      <div className="meta-box">
                        <ion-icon name="time"></ion-icon>

                        <p className="text">4:00 ማታ local time</p>
                      </div>
                    </li>

                    <li className="card-meta-item">
                      <div className="meta-box">
                        <ion-icon name="location"></ion-icon>

                        <p className="text">Ethiopia</p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="card-price">
                  <div className="wrapper"></div>

                  <p className="price">
                    ID 89087211519
                    <span>Passcode 756184</span>
                  </p>

                  <a
                    href="https://us06web.zoom.us/j/89087211519?pwd=NENhUC9WM3Z6RzBDMXZ1Umc1bWxVZz09 "
                    stn btn-secondary"
                  >
                    Join Meeting
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section> */}
    </>
  );
}

function getYouTubeID(url) {
  var regExp =
    /^.*(youtu.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|\&v=|youtu.be\/|\/embed\/)([^#\&\?]*).*/;
  var match = url.match(regExp);
  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return null;
  }
}
