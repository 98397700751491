import React from "react";
import BookPurchase from "./BookPurchase";

export default function AboutUs() {
  const lang = [
    "1. We are a tribe of life",
    "2. We are the tribe of Enoch",
    "3. If you join our community, you will live.",
    "4. Eternal life is our consciousness",
    "5. We don’t die",
    "6. We have eternal life",
    "7. The only difference between us and other believers is that we dare and strive to lay hold on eternal life and dominate the works of evil through The Anointing of God.",
  ];

  const cardBelief = [
    "1. the world is Gods",
    "2. The Bible is the word of God",
    "3. Jesus is the son of God and he is the answer. ( the way, the truth and the life).",
    "4. We believe in God the father , son and the Holy Spirit (the God head)",
    "5. We believe in the return of Jesus.",
    "6. Each of us is one in the image of God.",
    "7. The church has an awesome responsibility and opportunity",
    "8. Worship is the focal point in the life of the Anointed church (believers)",
  ];
  return (
    <>
      {" "}
      <section
        style={{ marginTop: "30px" }}
        className="section about why-us"
        id="about"
        aria-label="about"
      >
        <div className="container">
          <div
            className="about-banner img-holder"
            style={{ "--width": "720", "--height": "700" }}
          >
            <img
              src="/assets/images/proph2.jpg"
              width="720"
              height="960"
              loading="lazy"
              alt="about banner"
              className="img-cover"
            />
          </div>

          <div className="about-content">
            <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch">
              <div className="content">
                <h2
                  style={{
                    marginInline: "auto",
                    fontSize: "25px",
                    textAlign: "center",
                  }}
                  className="h2 section-subtitle"
                >
                  What we Beleive
                </h2>
                <p>
                  {/* Whether you're seeking a new church home, exploring faith for
                  the first time, or looking to deepen your spiritual journey,
                  JPS Ministry welcomes you with open arms. Come and be a part
                  of our family, where you can grow in faith, serve with love,
                  and experience the transformative power of God's grace. */}
                </p>
              </div>

              <div className="accordion-list">
                <ul>
                  <li>
                    <a
                      data-bs-toggle="collapse"
                      className="collapse"
                      data-bs-target="#accordion-list-1"
                    >
                      <span>01</span> OUR LANGUAGE
                      <i className="bx bx-chevron-down icon-show"></i>
                      <i className="bx bx-chevron-up icon-close"></i>
                    </a>
                    <div
                      id="accordion-list-1"
                      className="collapse show"
                      data-bs-parent=".accordion-list"
                    >
                      <p>
                        {lang.map((l) => (
                          <li
                            key={l}
                            className="about-item"
                            style={{ padding: "0px 15px" }}
                          >
                            {l}
                          </li>
                        ))}
                      </p>
                    </div>
                  </li>

                  <li>
                    <a
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-list-2"
                      className="collapsed"
                    >
                      <span>02</span> OUR BENEDICTION
                      <i className="bx bx-chevron-down icon-show"></i>
                      <i className="bx bx-chevron-up icon-close"></i>
                    </a>
                    <div
                      id="accordion-list-2"
                      className="collapse"
                      data-bs-parent=".accordion-list"
                    >
                      <p>
                        <ul>
                          <li style={{ padding: "0px 15px" }}>
                            1. Immortality
                          </li>
                          <li style={{ padding: "0px 15px" }}>
                            2. death impossible
                          </li>
                          <li style={{ padding: "0px 15px" }}>
                            3. Divine health
                          </li>
                          <li style={{ padding: "0px 15px" }}>
                            4. sickness impossible
                          </li>
                          <li style={{ padding: "0px 15px" }}>
                            5. Divine protection
                          </li>
                          <li style={{ padding: "0px 15px" }}>
                            6. destruction impossible Divine provision
                          </li>
                          <li style={{ padding: "0px 15px" }}>
                            7. poverty impossible Divine direction
                          </li>
                          <li style={{ padding: "0px 15px" }}>
                            8. mistakes impossible
                          </li>
                          <li style={{ padding: "0px 15px" }}>
                            9. Holy communion, Covid-19 impossible
                          </li>
                        </ul>
                      </p>
                    </div>
                  </li>

                  <li>
                    <a
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-list-3"
                      className="collapsed"
                    >
                      <span>03</span> OUR CARDINAL BELIEF
                      <i className="bx bx-chevron-down icon-show"></i>
                      <i className="bx bx-chevron-up icon-close"></i>
                    </a>
                    <div
                      id="accordion-list-3"
                      className="collapse"
                      data-bs-parent=".accordion-list"
                    >
                      <p>
                        {" "}
                        We believe that: <br />
                        <ul>
                          {cardBelief.map((c) => (
                            <li
                              key={c}
                              style={{
                                padding: "0px 15px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {c}
                            </li>
                          ))}
                        </ul>
                      </p>
                    </div>
                  </li>
                  <li>
                    <a
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-list-4"
                      className="collapsed"
                    >
                      <span>04</span> WATER BAPTISM
                      <i className="bx bx-chevron-down icon-show"></i>
                      <i className="bx bx-chevron-up icon-close"></i>
                    </a>
                    <div
                      id="accordion-list-4"
                      className="collapse"
                      data-bs-parent=".accordion-list"
                    >
                      <p>
                        1. We believe in water baptism in the name of the
                        Father, The son and the Holy Spirit. We believe that
                        baptism is an outward expression of an inward
                        experience.
                        <br />
                        2. We believe in water immersion as our Lord Jesus
                        Christ was baptized by John the Baptist, having your
                        whole body fully immersed in the water as a form of
                        identification with the death, burial and resurrection
                        of our Lord and saviour Jesus the Christ. <br />
                        3. Mark 16:16 In-Context15 And he said unto them,Go ye
                        into all the world, and preach the gospel to every
                        creature. 16 He that believeth and is baptized shall be
                        saved; but he that believeth not shall be damned.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* <p style={{ fontSize: "2rem" }} className="section-text">
              OUR LANGUAGE
              <ul className="about-list">
                {lang.map((l) => (
                  <li className="about-item">
                    <ion-icon
                      name="checkmark-circle"
                      aria-hidden="true"
                    ></ion-icon>

                    <p style={{ fontSize: "2rem" }} className="section-text">
                      {l}
                    </p>
                  </li>
                ))}
              </ul>
              {}
            </p> */}

            {/* <!--<h3 className="h3">Who We Are</h3>--> */}

            {/* <p style={{ fontSize: "2rem" }} className="section-text">
              በጌታ የተወደዳችሁ የክርስቶስ ቤተሰቦች: ባለፉት ጥቂት አመታት በዚህ ወንጌል አገልግሎት ከቤተ ክርስቲያን
              ጋር አብራችሁ በገንዘባችሁ፣ በምክራችሁ ፣በፀሎታችሁ ፣በጉልበታችሁ ከጎናችን የቆማችሁ ሁላችሁንም
              እግዚአብሄር አምላክ አብዝቶ ይባርካችሁ።
            </p> */}

            {/* <!--<h3 className="h3">Our Success</h3>--> */}

            {/* <ul className="about-list">
              <li className="about-item">
                <ion-icon name="checkmark-circle" aria-hidden="true"></ion-icon>

                <p style={{ fontSize: "2rem" }} className="section-text">
                  "...አሁን ሥራችሁን እንዲሁም በፊትም ሆነ አሁን ቅዱሳንን በመርዳት ስለ ስሙ ያሳያችሁትን ፍቅር
                  አይረስም።"
                </p>
              </li>

              <li className="about-item">
                <ion-icon name="checkmark-circle" aria-hidden="true"></ion-icon>

                <p style={{ fontSize: "2rem" }} className="section-text">
                  በተጨማሪም ይህን መልእክት ለሌሎች በማህበራዊ ድህረ ገጾች በማጋራት አብረውን ይተባበሩ።
                </p>
              </li>

              <li className="about-item">
                <ion-icon name="checkmark-circle" aria-hidden="true"></ion-icon>

                <p style={{ fontSize: "2rem" }} className="section-text">
                  እግዚአብሄር ዘመናችሁን፣ ህይወታችሁን፣ ሥራችሁን፣ አገልግሎታችሁን፣ ቤተሰባችሁን እና ሁለንተናችሁን
                  ይባርክ።
                </p>
              </li>
            </ul> */}
          </div>
        </div>
      </section>
      <BookPurchase />
      <section
        style={{ marginTop: "50px" }}
        className="section video"
        aria-label="video"
      >
        <div className="container">
          <div
            className="video-card has-before has-bg-image"
            style={{ backgroundImage: "url(/assets/images/video.jpg)" }}
          >
            <h2 className="h2 card-title">Explore Church Life</h2>

            <button className="play-btn" aria-label="play video">
              <a href="{{ route('services') }}">
                <ion-icon name="play-sharp" aria-hidden="true"></ion-icon>
              </a>
            </button>

            <a
              href="http://www.youtube.com/@ANOINTINGTVCHANNEL"
              className="btn-link has-before"
            >
              Watch More
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
